import React, { useState, useEffect, FC} from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Alert, Card } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import sites from "../../../api/master/sites";
import { Modal, ModalBody } from "reactstrap";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import { FormProvider, useForm } from "react-hook-form";
import { Site } from "./types/Sites";
import usersApis from "../../../api/master/users";
import FormatDate from "../DateFormater";
import { useSelector } from "react-redux";
interface SelectOptions {
  label: string;
  value: string;
}
const SitesViewDetails: FC = () => {
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

    useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { siteId } = useParams();
  const [data, setData]: any = useState();
  const [modalGI, setGIModal] = useState(false);
  const [formData, setFormData] = useState({
    locationName: "",
    locationType: "",
    referenceID: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    district: "",
    city: "",
    pincode: "",
  });
  const methods = useForm<Site>({
    mode: "onChange",
    defaultValues: {
      locationName: " ",
      locationType: " ",
      referenceID: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
      state: "",
      district: "",
      city: "",
      pincode: "",
    },
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
  } = methods;

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [modalEditBlockName, setModalEditBlockName]: any = useState("");
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const selectedCountry = watch("country");
  useEffect(() => {
    if (selectedCountry) {
      fetchStatesByCountry(selectedCountry);
    }
  }, [selectedCountry]);

 
  const onSubmit = async (data: any) => {
    console.log("data", data);
    data.country = data.country.countryId;
    try {
      const { status, message } = await sites.updateSitesById(siteId, data);
      if (status) {
        Swal.fire(
          "Updated!",
          "Manufacturing Lcation has been updated",
          "success"
        );
        setGIModal(false);
        fetchSiteDetails(siteId);
      } else {
        let errorMessage = "An error occurred";
        const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
        setAlertInfo({
          type: "error",
          message: fullErrorMessage,
          errors: [],
        });
      }
    } catch (error: any) {
      // Swal.fire("Failed!", error.response.data.message, "error");
      setAlertInfo({
        type: "error",
        message: error.response.data.message,
        errors: [],
      });
    }
  };

  
  const fetchCountrys = async (id?: string) => {
    try {
      const data = await usersApis.getCountrys();
      const countryOptions = data?.data?.map((country: any) => ({
        label: country.countryName,
        value: country.countryId.toString(),
      }));
      setCountries(countryOptions);
      if (id) {
        const country = countryOptions.find(
          (country: SelectOptions) => country.value === id.toString()
        );
        if (!country) {
          return;
        }
        setValue("country", id);
      }
    } catch (error) {}
  };

  const getDistricts = async (stateID: any, districtID?: any) => {
    console.log("districtID,stateID", stateID, districtID);
    try {
      const districts = await sites.getDistrictsByState(stateID);
      // console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
          if (districtID) {
            console.log("districtID", districtID);
            setValue("district", districtID.toString());
          } else {
            setValue("district", districts.data[0].districtId.toString());
          }
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const onStateChange = (e: any) => {
    console.log("e", e);
    getDistricts(e);
  };
  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
        setValue("state", state.value);
      }
    } catch (error) {}
  };
  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };
  // const selectedState = watch("state");

  useEffect(() => {
    viewChange();
    fetchCountrys();

    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = siteId;
    if (id !== undefined || null || "") {
      fetchSiteDetails(id);
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [siteId]);

  const openEditModal = (modalEditBlockName: any) => {
    // console.log("data.state.stateID", data);
    fetchStatesByCountry("1");
    getDistricts(data.state.stateId, data.district.districtId);
    setModalEditBlockName(modalEditBlockName);
    setGIModal(true);
    // getCountrys()
  };

  const fetchSiteDetails = async (id: any) => {
    try {
      const { data, status, message } = await sites.fetchSiteWithBlockById(id);
      if (status) {
        const newData = data;
        const {
          locationName,
          locationType,
          referenceID,
          addressLine1,
          addressLine2,
          country,
          state,
          district,
          city,
          pincode,
        } = data;
        console.log(data);

        setData(newData); // Update data with fetched response
        fetchStatesByCountry("1");
        setFormData({
          locationName: newData.locationName,
          locationType: newData.locationType,
          referenceID: newData.referenceID,
          addressLine1: newData.addressLine1,
          addressLine2: newData.addressLine2,
          country: newData.country,
          state: newData.state,
          district: newData.district,
          city: newData.city,
          pincode: newData.pincode,
        });
        setValue("locationName", locationName);
        setValue("locationType", locationType);
        setValue("referenceID", referenceID);
        setValue("country", country.countryId);
        setValue("state", state.stateId);
        getDistricts(state.stateId, district.districtId);
        setValue("city", city);
        // setValue("district", district);
        setValue("pincode", pincode);
        setValue("addressLine1", addressLine1);
        setValue("addressLine2", addressLine2);
      }
    } catch (err: any) {
      console.log(err);

      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  return (
    <React.Fragment>
      <Head title="Manufacturing Location Details"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Manufacturing Location Details -{" "}
                    <strong className="text-primary small">
                      {data["locationName"]}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  
                  <Link to={`${process.env.PUBLIC_URL}/sites`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">General Information</BlockTitle>
                        <p>Name, Address etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit?<Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("GeneralInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>:<></>}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Interanl ID</div>
                          <div className="data-value">{data.referenceID}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Location Name</div>
                          <div className="data-value">{data.locationName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Location Type</div>
                          <div className="data-value">{data.locationType}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Country</div>
                          <div className="data-value">
                            {data.country.countryName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">State</div>
                          <div className="data-value">
                            {data.state.stateName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">District</div>
                          <div className="data-value">
                            {data.district.districtName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">City</div>
                          <div className="data-value">{data.city}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Address Line 1</div>
                          <div className="data-value">{data.addressLine1}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Address Line 2</div>
                          <div className="data-value">{data.addressLine2}</div>
                        </div>
                      </li>

                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Pincode</div>
                          <div className="data-value">{data.pincode}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>

              {modalEditBlockName === "GeneralInformation" && (
                <>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <ModalBody>
                        <div className="">
                          <div className="nk-modal-head">
                            <h5 className="title">Edit General Information </h5>
                          </div>
                          {alertInfo.message && (
                            <div className="mb-3 mt-1">
                              <Alert
                                color={
                                  alertInfo.type === "error"
                                    ? "danger"
                                    : "success"
                                }
                                className="alert-icon"
                              >
                                <strong>{alertInfo.message}</strong>
                                <Icon
                                  name={
                                    alertInfo.type === "error"
                                      ? "alert-circle"
                                      : "check-circle"
                                  }
                                />
                                {alertInfo.errors.length > 0 ? (
                                  <ul>
                                    {alertInfo.errors.map(
                                      (err: any, index: any) => (
                                        <li key={index}>{err}</li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </Alert>
                            </div>
                          )}
                          <div className="nk-tnx-details mt-sm-3">
                            <Row className="gy-4">
                              <Col md="6">
                                <FormInput
                                  name="referenceID"
                                  label="Location ID"
                                  disabled
                                  required={true}
                                  type="text"
                                  maxLength={10}
                                  placeholder="Enter Location ID"
                                />
                              </Col>
                              <Col md="6">
                                <FormSelect
                                  name="locationType"
                                  label="Select Location Type"
                                  required={true}
                                  options={[
                                    { label: "Plant", value: "Plant" },
                                    {
                                      label: "Ware House",
                                      value: "Ware House",
                                    },
                                  ]}
                                />
                              </Col>
                              <Col md="6">
                                <FormInput
                                  name="locationName"
                                  label="Location Name"
                                  required={true}
                                  type="text"
                                  placeholder="Enter Location Name"
                                />
                              </Col>
                              <Col md="6">
                                <FormSelect
                                  name="country"
                                  label="Country"
                                  options={countries}
                                  required={true}
                                  // defaultValue={countries.find(
                                  //   (option: any) =>
                                  //     option.value == data.country.countryId
                                  // )}
                                  placeholder="Select Country"
                                />
                              </Col>
                              <Col md="6">
                                <FormSelect
                                  name="state"
                                  label="State"
                                  options={states}
                                  required={true}
                                  placeholder="Select State"
                                  onChange={(ev) => onStateChange(ev)}
                                  // defaultValue={states.find(
                                  //   (option: any) =>
                                  //     option.value == data.state.stateId
                                  // )}
                                  // onChange={(ev) => {
                                  //   onStateChange(ev);
                                  //   setValue("state",ev)
                                  // }}
                                />
                              </Col>
                              <Col md="6">
                                <FormSelect
                                  name="district"
                                  label="District"
                                  options={districtOptions}
                                  required={true}
                                  placeholder="Select District"
                                  // defaultValue={districtOptions.find(
                                  //   (option: any) =>
                                  //     option.value == data?.district?.districtId
                                  // )}
                                  // onChange={(ev: any) => {
                                  //   console.log("onchangedistrict", ev);
                                  //   setValue("district", ev);
                                  // }}
                                />
                              </Col>
                              <Col md="6">
                                <FormInput
                                  name="city"
                                  label="City"
                                  required={true}
                                  placeholder="Enter City"
                                  type="text"
                                />
                              </Col>
                              <Col md="6">
                                <FormInput
                                  name="addressLine1"
                                  label="Address Line 1"
                                  required={true}
                                  placeholder="Enter Site Address"
                                  type="text"
                                />
                              </Col>
                              <Col md="6">
                                <FormInput
                                  name="addressLine2"
                                  label="Address Line 2"
                                  // required={true}
                                  placeholder="Enter Address Line 2"
                                  type="text"
                                />
                              </Col>
                              <Col lg="6">
                                <FormInput
                                  name="pincode"
                                  label="Zip/Pincode"
                                  required={true}
                                  type="number"
                                  placeholder="Enter Zip or pin code"
                                />
                              </Col>

                              <Col size="12">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                  <li>
                                    <Button
                                      color="primary"
                                      size="lg"
                                      type="submit"
                                    >
                                      Update
                                    </Button>
                                  </li>
                                  <li>
                                    <a
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setGIModal(false);
                                      }}
                                      className="link link-light"
                                    >
                                      Cancel
                                    </a>
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </ModalBody>
                    </form>
                  </FormProvider>
                </>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default SitesViewDetails;
