import React, { useState, useEffect, FC } from "react";
import Content from "../../../layout/content/Content";
import { Row, Col } from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Button,
  Icon,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
} from "../../../components/Component";
import "react-tooltip/dist/react-tooltip.css";
import Swal from "sweetalert2";
import { Supplier } from "./types/SupplierCategory";
import supplierApis from "../../../api/master/supplier";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Modal,
  ModalBody,
} from "reactstrap";

import { Link } from "react-router-dom";
import moment from "moment";
import EditModal from "./EditModalSupplierCategory";
import AddModal from "./AddModalSupplierCategory";
import Export from "../../../components/export/Export";
import { useSelector } from "react-redux";

const SupplierCategory: FC = () => {
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [sm, updateSm] = useState(false);
  const [modal, setModal]: any = useState({
    edit: false,
    add: false,
  });
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const [editId, setEditedId] = useState();
  const [viewModal, setViewModal] = useState(false);
  const [detail, setDetail]: any = useState({});
  const [formData, setFormData] = useState({
    referenceID: "",
    category: "",
    description: "",
    materialID: "",
  });
  const [editFormData, setEditFormData] = useState({
    referenceID: "",
    category: "",
    description: "",
    materialID: "",
  });

  const [originalData, setOriginalData] = useState([]);

  const closeModal = () => {
    setModal({ add: false });
    resetForm();
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
  };

  const closeEditModal = () => {
    setModal({ edit: false });
    resetForm();
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
  };

  const resetForm = () => {
    setFormData({
      referenceID: "",
      category: "",
      description: "",
      materialID: "",
    });
  };

  const onFormSubmit = async (submitData: any) => {
    console.log(submitData, "submitData");
    const response = await supplierApis.createSupplierCategory(submitData);
    const { status, message } = response;
    if (status) {
      Swal.fire("Created!", "Material Category has been created", "success");
      resetForm();
      setAlertInfo({
        type: "", // 'success' or 'error'
        message: "",
        errors: [],
      });
      setModal({ edit: false }, { add: false });
      fetchData();
    } else {
      let errorMessage = "An error occurred";
      const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };

  const onEditSubmit = async (submitData: any) => {
    console.log("1111:::;EDIT", submitData);
    const response = await supplierApis.updateSupplierCategoryById(
      editId,
      submitData
    );
    const { status, message } = response;
    if (status) {
      Swal.fire("Updated!", "Material Category has been updated", "success");
      resetForm();
      setAlertInfo({
        type: "", // 'success' or 'error'
        message: "",
        errors: [],
      });
      setModal({ edit: false }, { add: false });
      fetchData();
    } else {
      let errorMessage = "An error occurred";
      const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };

  const loadDetail = (id: any) => {
    let index = rowData.findIndex((item: any) => item.id === id);
    setDetail(rowData[index]);
  };

  const onEditClick = (id: any) => {
    rowData.forEach((item: any) => {
      if (item.id === id) {
        setEditFormData({
          referenceID: item.referenceID,
          materialID: item.materialID,
          category: item.category,
          description: item.description,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(id);
      }
    });
  };

  const onFilterChange = (e: any) => {
    setSearchText(e.target.value);
  };

  const [rowData, setRowData] = useState<Supplier[]>([]);
  const handleDelete = async (supplierCategoryID: any) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        allowOutsideClick: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary",
        },
      });

      if (result.isConfirmed) {
        const { status, message } =
          await supplierApis.deleteSupplierCategoryById(supplierCategoryID);
        if (status) {
          Swal.fire(
            "Deleted!",
            "Material Category has been deleted",
            "success"
          );
          fetchData();
        } else {
          Swal.fire("Error", message, "error");
        }
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  const fetchData = async () => {
    try {
      const { data, status, message } =
        await supplierApis.fetchSupplierCategory();
      if (status) {
        setRowData(data);
        setOriginalData(data); // Store the original data
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (onSearchText !== "") {
      const searchText = onSearchText.toLowerCase();

      const filteredObject = originalData.filter((item: any) => {
        return Object.values(item).some((value: any) => {
          // Convert value to string and then perform case-insensitive search
          return value && value.toString().toLowerCase().includes(searchText);
        });
      });
      setRowData(filteredObject); // Update state with filtered data
    } else {
      setRowData(originalData); // Reset to original data
    }
  }, [onSearchText, originalData]);

  const sortFunc = (params: any) => {
    let defaultData = rowData;
    if (params === "asc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        a.createdDate.localeCompare(b.createdDate)
      );
      setRowData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        b.createdDate.localeCompare(a.createdDate)
      );
      setRowData([...sortedData]);
    }
  };
  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = rowData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="Construction Sites"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Material Category Lists
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {rowData.length} Material Category.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Export data={rowData} />
                    </li>
                    {allowedActions?.add ? (
                      <li className="nk-block-tools-opt">
                        <Button
                          color="primary"
                          onClick={() => {
                            setModal({ add: true });
                            setAlertInfo({
                              type: "", // 'success' or 'error'
                              message: "",
                              errors: [],
                            });
                          }}
                        >
                          <Icon name="plus"></Icon>
                          <span>Add </span>
                        </Button>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable
            className="card-stretch"
            bodyClassName={undefined}
            title={undefined}
          >
            <div className="card-inner position-relative card-tools-toggle">
              <div className={`card-search search-wrap active`}>
                <div className="card-body">
                  <div
                    className="search-content"
                    style={{ whiteSpace: "nowrap", display: "flex" }}
                  >
                    <Button className="search-back btn-icon toggle-search active">
                      <Icon name="search"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="btn btn-trigger btn-icon dropdown-toggle"
                      >
                        <Icon name="filter"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end className="dropdown-menu-xs">
                        <ul className="link-check">
                          <li>
                            <span>Show</span>
                          </li>
                          <li className={itemPerPage === 10 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(10);
                              }}
                            >
                              10
                            </DropdownItem>
                          </li>
                          <li className={itemPerPage === 15 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(15);
                              }}
                            >
                              15
                            </DropdownItem>
                          </li>
                        </ul>
                        <ul className="link-check">
                          <li>
                            <span>Order</span>
                          </li>
                          <li className={sort === "dsc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("dsc");
                                sortFunc("dsc");
                              }}
                            >
                              DESC
                            </DropdownItem>
                          </li>
                          <li className={sort === "asc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("asc");
                                sortFunc("asc");
                              }}
                            >
                              ASC
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
            </div>

            <DataTableBody compact className={undefined} bodyclass={undefined}>
              <DataTableHead>
                <DataTableRow className={undefined} size={undefined}>
                  <span className="sub-text">#</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Material ID</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Material Type</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Category Name</span>
                </DataTableRow>
                <DataTableRow size="sm" className={undefined}>
                  <span className="sub-text">Description</span>
                </DataTableRow>

                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  {/* <span className="sub-text">Details</span> */}
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item: any, index) => {
                    return (
                      <DataTableItem
                        key={item.id}
                        className={undefined}
                        style={{ color: "black" }}
                      >
                        <DataTableRow size="md" className={undefined}>
                          <span>{index + 1}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.referenceID}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.materialName}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.category}</span>
                        </DataTableRow>
                        <DataTableRow size="sm" className={undefined}>
                          <span>{`${item.description!.substring(
                            0,
                            25
                          )}... `}</span>
                          <TooltipComponent
                            iconClass="info-circle text-info"
                            icon="info"
                            id={`desc-tooltip-${item.id}`}
                            direction="top"
                            text={item.description}
                            containerClassName={""}
                          />
                          {/* <span>{item.description}</span> */}
                        </DataTableRow>

                        <DataTableRow size="md" className={undefined}>
                          <span
                            className={`tb-status text-${
                              item.status === "Active" ? "success" : "danger"
                            }`}
                          >
                            {item.status}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <ul className="nk-tb-actions">
                            {allowedActions?.edit ? (
                              <li
                                className="nk-tb-action-hidden"
                                onClick={() => onEditClick(item.id)}
                              >
                                <TooltipComponent
                                  tag="a"
                                  containerClassName="btn btn-trigger btn-icon"
                                  id={"edit" + item.id}
                                  icon="edit-alt-fill"
                                  direction="top"
                                  text="Edit"
                                  iconClass={undefined}
                                />
                              </li>
                            ) : (
                              <></>
                            )}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    {allowedActions?.edit ? (
                                      <li onClick={() => onEditClick(item.id)}>
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                          }}
                                        >
                                          <Icon name="edit"></Icon>
                                          <span>Edit</span>
                                        </DropdownItem>
                                      </li>
                                    ) : (
                                      <></>
                                    )}
                                    <li>
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          loadDetail(item.id);
                                          setViewModal(true);
                                        }}
                                      >
                                        <Icon name="focus"></Icon>
                                        <span>View Details</span>
                                      </Link>
                                    </li>
                                    {allowedActions?.cancel ? (
                                      <li>
                                        <DropdownItem
                                          tag="a"
                                          href="#edit"
                                          onClick={(ev) => {
                                            ev.preventDefault();
                                            handleDelete(item.id);
                                          }}
                                        >
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    ) : (
                                      <></>
                                    )}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={rowData.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        <AddModal
          modal={modal.add}
          formData={formData}
          setFormData={setFormData}
          closeModal={closeModal}
          onSubmit={onFormSubmit}
          filterStatus
          alertInfo={alertInfo}
        />
        <EditModal
          modal={modal.edit}
          formData={editFormData}
          setFormData={setEditFormData}
          closeModal={closeEditModal}
          onSubmit={onEditSubmit}
          filterStatus
          alertInfo={alertInfo}
        />
      </Content>

      <Modal
        isOpen={viewModal}
        toggle={() => setViewModal(false)}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalBody>
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setViewModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="nk-modal-head">
            <h4 className="nk-modal-title title">
              Material Category Details -{" "}
              <small className="text-primary"> {detail.category}</small>
            </h4>
          </div>
          <div className="nk-tnx-details mt-sm-3">
            <Row className="gy-3">
              <Col lg={6}>
                <span className="sub-text"> Reference ID</span>
                <span className="caption-text">{detail.referenceID}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Material Type</span>
                <span className="caption-text text-break">
                  {detail.materialName}
                </span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Category</span>
                <span className="caption-text">{detail.category}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Status</span>
                <Badge
                  color={detail.status === "Active" ? "success" : "danger"}
                  size="md"
                >
                  {detail.status}
                </Badge>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Description</span>
                <span className="caption-text"> {detail.description}</span>
              </Col>
              <Col lg={6}>
                <span className="sub-text">Created Date</span>
                <span className="caption-text">
                  {" "}
                  {moment(detail.createdDate).format("DD MMM YYYY")}
                </span>
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SupplierCategory;
