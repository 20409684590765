import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  TruckOutlined,
} from '@ant-design/icons';

const statusMapper: any = {
  // "Pending Approval": {
  //   buyer: {
  //     title: "Pending Approval",
  //     color: "orange",
  //     icon: <ClockCircleOutlined />,
  //   },
  //   seller: {
  //     title: "Pending Approval",
  //     color: "orange",
  //     icon: <ClockCircleOutlined />,
  //   },
  // },
  "Approved": {
    buyer: {
      title: "Approved",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
    seller: {
      title: "Pending",
      color: "orange",
      icon: <ClockCircleOutlined />,
    },
  },
  // "Rejected": {
  //   buyer: {
  //     title: "Rejected",
  //     color: "red",
  //     icon: <CloseCircleOutlined />,
  //   },
  //   seller: {
  //     title: "Rejected",
  //     color: "red",
  //     icon: <CloseCircleOutlined />,
  //   },
  // },
  "To be Invoiced": {
    buyer: {
      title: "To be Invoiced",
      color: "purple",
      icon: <ExclamationCircleOutlined />,
    },
    seller: {
      title: "To be Invoiced",
      color: "purple",
      icon: <ExclamationCircleOutlined />,
    },
  },
  "Changes to Buyer": {
    buyer: {
      title: "Supplier Changes",
      color: "blue",
      icon: <SyncOutlined />,
    },
    seller: {
      title: "Changes Requested",
      color: "blue",
      icon: <SyncOutlined />,
    },
  },
  "Change request accepted by Buyer": {
    buyer: {
      title: "Change Accepted",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
    seller: {
      title: "Buyer Accepted Changes",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
  },
  "Changes from Buyer": {
    buyer: {
      title: "Changes Requested",
      color: "blue",
      icon: <SyncOutlined />,
    },
    seller: {
      title: "Changes from Buyer",
      color: "blue",
      icon: <SyncOutlined />,
    },
  },
  "Rejected by Supplier": {
    buyer: {
      title: "Supplier Rejected",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
    seller: {
      title: "Supplier Rejected",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
  },
  "Rejected from Buyer": {
    buyer: {
      title: "Changes Rejected",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
    seller: {
      title: "Buyer Rejected Changes",
      color: "red",
      icon: <CloseCircleOutlined />,
    },
  },
  "To be Delivered": {
    buyer: {
      title: "To be Delivered",
      color: "blue",
      icon: <TruckOutlined /> 
    },
    seller: {
      title: "To be Delivered",
      color: "blue",
      icon: <TruckOutlined />
    },
  },
  "Partially Delivered": {
    buyer: {
      title: "Partially Delivered",
      color: "orange",
      icon: <ExclamationCircleOutlined />,
    },
    seller: {
      title: "Partially Delivered",
      color: "orange",
      icon: <ExclamationCircleOutlined />,
    },
  },
  "Fully Delivered": {
    buyer: {
      title: "Fully Delivered",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
    seller: {
      title: "Fully Delivered",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
  },

};

export default statusMapper;
