import React, { FC, useEffect, useState } from "react";
import { Row, Col, Button, Modal, ModalBody, Label } from "reactstrap";
import { Controller, useFormContext } from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import FormSelect from "../form-components/SelectComponent";
import FormDatePicker from "../form-components/DatePicker";
import invoiceApis from "../../api/Invoice/invoice";
import { Popover } from "antd";
import misc from "../../api/misc/dropDowns";
import usersApis from "../../api/master/users";
import sites from "../../api/master/sites";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSequenceAccess } from "../../store/slices/misc/sequenceAccess";
const GeneralDetails: FC = (props: any) => {
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [BuyerOptions, setBuyerOptions] = useState<any[]>([]);
  const [invoiceOptions, setInvoiceOptions] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [districtOptions1, setDistrictOptions1] = useState<any[]>([]);
  const [districtOptions2, setDistrictOptions2] = useState<any[]>([]);
  const dispatch:any = useDispatch();
  const { data: sequenceAccess } = useSelector(
    (state: any) => state.sequenceAccess
  );
  const AllFormDetails = watch();
  const BuyerIdWatch = watch("buyerId");
  const b_stateWatch = watch("b_state");
  const d_stateWatch = watch("d_state");
  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);
  useEffect(() => {
    if (b_stateWatch) {
      getDistricts(b_stateWatch, 1);
    }
  }, [b_stateWatch]);
  useEffect(() => {
    if (d_stateWatch) {
      getDistricts(d_stateWatch, 0);
    }
  }, [d_stateWatch]);

  useEffect(() => {
    if (BuyerIdWatch) {
      setValue(
        "buyerName",
        BuyerOptions.find((obj) => obj.value == BuyerIdWatch)?.label
      );
      fetchAllInvoicesForCreditDebitNote(BuyerIdWatch);
    }
  }, [BuyerIdWatch]);
  const fetchStatesByCountry = async (stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(1);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: any) => state.value === stateId
        );
        if (!state) {
          return;
        }
        setValue("state", state.value);
      }
    } catch (error) {}
  };

  const getDistricts = async (stateID: any, toggle?: any) => {
    try {
      const districts = await sites.getDistrictsByState(stateID);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          if (toggle) {
            setDistrictOptions1(transformedData);
          } else {
            setDistrictOptions2(transformedData);
          }
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const submitForm = (data: any) => {
    if (data?.againstInvoice != "Yes") {
      const billingAddress = JSON.stringify({
        deliveryLocationName: data?.b_LocationName,
        countryInfo: "India",
        state: states?.find((state: any) => state.value == data?.b_state)
          ?.label,
        district: districtOptions1?.find(
          (state: any) => state.value == data?.b_district
        )?.label,
        city: data?.b_city,
        addressLine1: data?.b_addressLine1,
        addressLine2: data?.b_addressLine2,
        pincode: data?.b_pincode,
      });
      setValue("billingAddress", billingAddress);
      const deliveryAddress = JSON.stringify({
        deliveryLocationName: data?.d_LocationName,
        countryInfo: "India",
        state: states?.find((state: any) => state.value == data?.d_state)
          ?.label,
        district: districtOptions2?.find(
          (state: any) => state.value == data?.d_district
        )?.label,
        city: data?.d_city,
        addressLine1: data?.d_addressLine1,
        addressLine2: data?.d_addressLine2,
        pincode: data?.d_pincode,
      });
      setValue("deliveryAddress", deliveryAddress);
    }
    props?.next();
  };

  const fetchBuyer = async () => {
    try {
      const res = await misc.fetchOrganizationResponseByKeys();
      const { data, status } = res;
      if (status) {
        let BuyerOptions = data
          ?.map((item: any) => ({
            label: item.name,
            value: item.orgID,
            addressLine1: item?.companyAddress?.addressLine1,
            addressLine2: item?.companyAddress?.addressLine2,
            country: item?.companyAddress?.country?.countryName,
            state: item?.companyAddress?.state?.stateName,
            district: item?.companyAddress?.district?.districtName,
            city: item?.companyAddress?.city,
            pincode: item?.companyAddress?.pincode,
          }))
          ?.filter((item: any) => item.value);
        setBuyerOptions(BuyerOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllInvoicesForCreditDebitNote = async (id: any) => {
    try {
      const res = await invoiceApis.fetchAllInvoicesForCreditDebitNote(id);
      const { data, status } = res;

      if (status) {
        let options = data.map((item: any) => ({
          label: `Invoice - ${item.invoiceNumber}`,
          value: item.invoiceNumber,
        }));
        setInvoiceOptions(options);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchStatesByCountry();
    fetchBuyer();
    const { noteType, BuyerAddress } = AllFormDetails;
    if (noteType) {
      setValue("noteType", noteType);
    } else {
      setValue("noteType", "Debit");
    }
    if (BuyerAddress) {
      setValue("BuyerAddress", BuyerAddress);
    }
  }, []);

  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
          {!sequenceAccess?.includes("Credit Note/Debit Note") ?
          <Col lg="4">
            <FormInput
              name="noteId"
              label={`Note Number`}
              required={true}
              placeholder="Enter Note Number"
            />
          </Col>:<></>}
          <Col lg="8">
            <FormInput
              name="description"
              label="Description"
              required={true}
              type="text"
              minLength={2}
              maxLength={200}
              placeholder="Enter Description"
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="buyerId"
              label={`Buyer`}
              options={BuyerOptions?.map((item: any) => ({
                label: item.label,
                value: item.value,
              }))}
              required={true}
              placeholder="-Select-"
            />
          </Col>

          <Col lg="4">
            <FormSelect
              name="noteType"
              label="Note Type"
              options={[
                { label: "Debit", value: "Debit" },
                { label: "Credit", value: "Credit" },
              ]}
              required={true}
              placeholder="-Select-"
            />
          </Col>

          <Col lg="4">
            <FormDatePicker
              name="noteDate"
              label="Debit Note Date"
              required={true}
              maxDate={new Date()}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="approvalRequired"
              label="Buyer Approval Required ?"
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              placeholder="Select"
              required={true}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="againstInvoice"
              label="Against Invoice"
              options={[
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ]}
              placeholder="-Select-"
              required={true}
            />
          </Col>

          <Col lg="4">
            <FormInput
              name="referenceNumber"
              label="Reference #"
              type="text"
              placeholder="Enter Reference Number"
            />
          </Col>
          {watch("againstInvoice") == "Yes" ? (
            <>
              <Col lg="4">
                <FormSelect
                  name="invoiceNumber"
                  label="Invoice"
                  options={invoiceOptions}
                  required={true}
                  placeholder="Select Invoice"
                />
              </Col>
            </>
          ) : (
            <>
              <div style={{ marginTop: "15px" }}></div>
              <h6 className="mb-0">Billing Address</h6>
              <div className="col-md-4">
                <FormInput
                  name="b_LocationName"
                  label="Billing Location Name"
                  type="text"
                  placeholder="Enter Delivery Location Name"
                  required={true}
                />
              </div>
              <div className="col-md-4">
                <FormSelect
                  name="b_state"
                  label="State"
                  options={states}
                  required={true}
                  placeholder="Select State"
                  onChange={(ev) => getDistricts(ev, 1)}
                />
              </div>
              <div className="col-md-4">
                <FormSelect
                  name="b_district"
                  label="District"
                  options={districtOptions1}
                  required={true}
                  placeholder="Select District"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  name="b_city"
                  label="City"
                  placeholder="Enter City"
                  required={true}
                  type="text"
                />
              </div>

              <div className="col-md-4">
                <FormInput
                  type="text"
                  name="b_addressLine1"
                  label="Address Lane 1"
                  required={true}
                  placeholder="Enter Address"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="text"
                  name="b_addressLine2"
                  label="Address Lane 2"
                  placeholder="Enter Address"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="number"
                  name="b_pincode"
                  label="Pincode/Zipcode"
                  placeholder="Enter Pincode/Zipcode"
                  required={true}
                />
              </div>
              <div style={{ marginTop: "15px" }}></div>
              <h6 className="mb-0">Delivery Address</h6>
              <div className="col-md-4">
                <FormInput
                  name="d_LocationName"
                  label="Delivery Location Name"
                  type="text"
                  placeholder="Enter Delivery Location Name"
                  required={true}
                />
              </div>
              <div className="col-md-4">
                <FormSelect
                  name="d_state"
                  label="State"
                  options={states}
                  required={true}
                  placeholder="Select State"
                  onChange={(ev) => getDistricts(ev, 0)}
                />
              </div>
              <div className="col-md-4">
                <FormSelect
                  name="d_district"
                  label="District"
                  options={districtOptions2}
                  required={true}
                  placeholder="Select District"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  name="d_city"
                  label="City"
                  placeholder="Enter City"
                  required={true}
                  type="text"
                />
              </div>

              <div className="col-md-4">
                <FormInput
                  type="text"
                  name="d_addressLine1"
                  label="Address Lane 1"
                  required={true}
                  placeholder="Enter Address"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="text"
                  name="d_addressLine2"
                  label="Address Lane 2"
                  placeholder="Enter Address"
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="number"
                  name="d_pincode"
                  label="Pincode/Zipcode"
                  placeholder="Enter Pincode/Zipcode"
                  required={true}
                />
              </div>
            </>
          )}
        
          <Col lg="8">
            <FormInput
              name="remarks"
              label="Remarks"
              type="text"
              placeholder="Enter Remarks"
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default GeneralDetails;
