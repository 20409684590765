import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Button, Card } from "reactstrap";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import { DatePicker, Empty, Flex, Spin, Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import FormSelect from "../form-components/SelectComponent";
import reportApis from "../../api/reports";
import { formatCurrency } from "../../utils/Utils";
import Export from "../../components/export/Export";
// import customerApis from "../../api/master/customer";
const { RangePicker } = DatePicker;

//  This template has  customer, dateRange, customDateRange, aging, intervalOfDays filters with table pagination

function DefaultReportTemplate({
  pageTitle,
  moduleKey,
  columns,
  filtersOption,
  previousPath,
  extra,
}: {
  pageTitle: string;
  moduleKey: string;
  columns: any;
  filtersOption: {
    dateRange?: boolean;
    customer?: boolean;
    aging?: boolean;
  };
  previousPath: string;
  extra?: any;
}) {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { data: currentUser } = useSelector(
    (state: RootState) => state.currentUser
  );
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [customerOptions, setCustomerOptions] = useState<any>([]);
  const [customDateRange, setCustomDateRange] = useState<any>();
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    defaultCurrent: 1,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30", "40", "50"],
  });

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const customer = watch("customer");
  const dateRange = watch("dateRange");
  const aging = watch("aging");
  const intervalOfDays = watch("intervalOfDays");

  const fetchData = async (params: any) => {
    try {
      let response: any = {};
      switch (moduleKey) {
        // Sales Report Start --------------------------------
        case "salesByCustomerReport":
          response = await reportApis.salesByCustomerReport(params);
          break;
        case "invoicesForSalesByCustomerReport":
          response = await reportApis.invoicesForSalesByCustomerReport({
            ...params,
            customerId: extra?.id,
          });
          break;
        case "salesByMaterialReport":
          response = await reportApis.salesByMaterialReport(params);
          break;
        case "invoicesForSalesByMaterialReport":
          response = await reportApis.invoicesForSalesByMaterialReport({
            ...params,
            materialId: extra?.id,
          });
          break;

        case "salesInvoiceDetailsReport":
          response = await reportApis.salesInvoiceDetailsReport(params);
          break;
        // Sales Report End --------------------------------

        //  Receivables Report Start --------------------------------
        case "customerBalancesReport":
          response = await reportApis.customerBalancesReport(params);
          break;
        case "customerBalancesDetailReport":
          response = await reportApis.customerBalancesDetailReport({
            ...params,
            customerId: extra?.id,
          });
          break;

        case "receivablesAgingSummaryReport":
          response = await reportApis.receivablesAgingSummaryReport(params);
          break;
        case "receivablesAgingSummaryDetailReport":
          response = await reportApis.receivablesAgingSummaryDetailReport({
            ...params,
            customerId: extra?.id,
          });
          break;

        case "paymentsReceivedByCustomerReport":
          response = await reportApis.paymentsReceivedByCustomerReport(params);
          break;
        case "paymentsReceivedByCustomerDetailReport":
          response = await reportApis.paymentsReceivedByCustomerDetailReport({
            ...params,
            transactionNumber: extra?.id,
          });
          break;

        case "deliveryNoteByCustomerReport":
          response = await reportApis.deliveryNoteByCustomerReport(params);
          break;
        case "deliveryNoteByCustomerDetailReport":
          response = await reportApis.deliveryNoteByCustomerDetailReport({
            ...params,
            customerId: extra?.id,
          });
          break;

        //  Receivables Report End--------------------------------

        default:
          alert("Invalid Module Key");
      }
      const { response: _response, status }: any = response;
      if (status) {
        setDataSource(_response?.data);
        setPagination((pre: any) => ({
          ...pre,
          current: _response?.pagination?.currentPage,
          pageSize: _response?.pagination?.pageSize,
          total: Number(_response?.pagination?.totalItems),
        }));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const convertToDates = (dateType: string) => {
    try {
      switch (dateType) {
        case "Month to Date":
          return {
            from: dayjs().startOf("month").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        case "Quarter to Date":
          return {
            from: dayjs()
              .subtract(3, "month")
              .startOf("month")
              .format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        case "Year to Date":
          return {
            from: dayjs().startOf("year").format("YYYY-MM-DD"),
            to: dayjs().format("YYYY-MM-DD"),
          };
        default:
          if (!customDateRange)
            return {
              from: dayjs()?.format("YYYY-MM-DD"),
              to: dayjs()?.format("YYYY-MM-DD"),
            };
          return {
            from: dayjs(customDateRange[0]).format("YYYY-MM-DD"),
            to: dayjs(customDateRange[1]).format("YYYY-MM-DD"),
          };
      }
    } catch (error) {
      return {
        from: dayjs()?.format("YYYY-MM-DD"),
        to: dayjs()?.format("YYYY-MM-DD"),
      };
    }
  };
  const formatParamsForFetchData = ({
    customer,
    dateRange,
    customDateRange,
    pagination,
    aging,
    intervalOfDays,
  }: any) => {
    if (customer) {
      if (
        (dateRange == "Custom Date" && !customDateRange) ||
        (filtersOption?.customer && !customer) ||
        (filtersOption?.aging && !aging) ||
        (filtersOption?.aging && !intervalOfDays)
      )
        return;
      setLoading(true);
      const { from, to } = convertToDates(dateRange);
      let paramsObj: any = {};
      if (filtersOption?.customer) paramsObj.customerId = customer;
      if (filtersOption?.aging) paramsObj.interval = aging;
      if (filtersOption?.aging) paramsObj.intervalDays = intervalOfDays;
      fetchData({
        ...paramsObj,
        startDate: from,
        endDate: to,
        page: pagination.current,
        pageSize: pagination.pageSize,
      });
    } else if (!filtersOption?.customer) {
      setLoading(true);
      fetchData({
        page: pagination.current,
        pageSize: pagination.pageSize,
      });
    }
  };
  useEffect(() => {
    formatParamsForFetchData({
      aging,
      intervalOfDays,
      customer,
      dateRange,
      customDateRange,
      pagination: {
        current: 1,
        pageSize: pagination?.pageSize,
      },
    });
  }, [customer, dateRange, customDateRange, aging, intervalOfDays]);

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth >= 990) {
      setSm(false);
    }
  };

  const fetchCustomerFilter = async () => {
    try {
      if (!filtersOption.customer) return;
      const { data, status } = await reportApis.fetchAllCustomersBySupplier();
      if (status) {
        let _customerOptions: any = [];
        let allCustomers: any = [];
        data.forEach((item: any) => {
          allCustomers.push(item?.orgID?.toString());
          _customerOptions.push({
            label: item?.name,
            value: item?.orgID?.toString(),
          });
        });
        setValue("customer", allCustomers?.join(","));
        if (_customerOptions.length > 1) {
          _customerOptions = [
            {
              label: "ALL",
              value: allCustomers?.join(","),
            },
            ..._customerOptions,
          ];
        }
        setCustomerOptions(_customerOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    viewChange();
    fetchCustomerFilter();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  useEffect(() => {
    if (currentUser) {
      if (filtersOption?.dateRange) {
        setValue("dateRange", "Quarter to Date");
      }
      if (filtersOption?.aging) {
        setValue("aging", 3);
        setValue("intervalOfDays", 15);
      }
    }
  }, [currentUser]);

  const getAgingIntervalColumns = () => {
    if (aging == 1) {
      return [
        {
          title: ">= 1 days",
          dataIndex: ">= 1 days",
          key: ">= 1 days",
          render: (obj: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>
                {formatCurrency(obj?.Amount?.toString(), record?.currencyCode)}
                {" ("}
                {obj?.Percentage}
                {")"}
              </span>
            </div>
          ),
        },
      ];
    } else {
      let newColumns: any = [];
      let i = 0,
        intervalCount = 0;
      for (i = 0; i < aging - 1; i++) {
        newColumns.push({
          title: `${intervalCount + 1}-${intervalCount + intervalOfDays} days`,
          dataIndex: `${intervalCount + 1}-${
            intervalCount + intervalOfDays
          } days`,
          key: `${intervalCount + 1}-${intervalCount + intervalOfDays} days`,
          render: (obj: any, record: any) => (
            <div style={{ textAlign: "right" }}>
              <span>
                {formatCurrency(obj?.Amount?.toString(), record?.currencyCode)}
                {" ("}
                {obj?.Percentage}
                {")"}
              </span>
            </div>
          ),
        });
        intervalCount += intervalOfDays;
      }
      newColumns.push({
        title: `> ${intervalCount} days`,
        dataIndex: `>= ${intervalCount + 1} days`,
        key: `>= ${intervalCount + 1} days`,
        render: (obj: any, record: any) => (
          <div style={{ textAlign: "right" }}>
            <span>
              {formatCurrency(obj?.Amount?.toString(), record?.currencyCode)}
              {" ("}
              {obj?.Percentage}
              {")"}
            </span>
          </div>
        ),
      });
      return newColumns;
    }
  };

  return (
    <>
      <Head title="View All Reports" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className="card-inner card-inner-lg"
              style={{ minHeight: "75vh" }}
            >
              {sm && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>{pageTitle}</span>
                    </BlockTitle>
                  </BlockHeadContent>
                  <div className="actions clearfix">
                    <ul
                      className="m-auto d-flex align-items-center justify-content-center p-0"
                      style={{
                        flexDirection: "row-reverse",
                      }}
                    >
                      <li>
                        <Export data={dataSource} size="sm" name={moduleKey} />
                      </li>
                      <li className="pt-0 pb-0">
                        <Button
                          className="toggle"
                          color="primary"
                          size="sm"
                          onClick={async () => {
                            navigate(previousPath);
                          }}
                        >
                          <Icon name="arrow-left" />
                          <span>Back</span>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </BlockBetween>
              </BlockHead>
              {/* content start here */}
              <div className="d-flex align-items-center flex-wrap mb-2">
                <FormProvider {...methods}>
                  <form className="w-100 d-flex align-items-center justify-content-start">
                    {filtersOption?.customer && (
                      <div
                        className="mt-1 mb-0 w-20"
                        style={{ marginRight: 10 }}
                      >
                        <FormSelect
                          name="customer"
                          required={true}
                          hideRequiredMark={true}
                          label="customer Filter"
                          placeholder="Select customer"
                          options={customerOptions}
                        />
                      </div>
                    )}

                    {filtersOption?.dateRange && (
                      <div className="w-50 d-flex align-items-end justify-content-start">
                        <div
                          className="mt-1 mb-0 w-40"
                          style={{ marginRight: 10 }}
                        >
                          <FormSelect
                            name="dateRange"
                            required={true}
                            hideRequiredMark={true}
                            // label=""
                            label={"Date Range Filter"}
                            // optionLabel="Date Range"
                            placeholder="Select Date Range"
                            options={[
                              {
                                value: "Month to Date",
                                label: "Month to Date",
                              },
                              {
                                value: "Quarter to Date",
                                label: "Quarter to Date",
                              },
                              { value: "Year to Date", label: "Year to Date" },
                              { value: "Custom Date", label: "Custom Date" },
                            ]}
                          />
                        </div>
                        <div className="mt-1 mb-0 w-50">
                          {dateRange === "Custom Date" && (
                            <RangePicker
                              value={
                                customDateRange
                                  ? [
                                      dayjs(customDateRange[0].toDate()),
                                      dayjs(customDateRange[1].toDate()),
                                    ]
                                  : null
                              }
                              onChange={(dates) => {
                                if (dates && dates[0] && dates[1]) {
                                  setCustomDateRange([dates[0], dates[1]]);
                                } else {
                                  setCustomDateRange(null);
                                }
                              }}
                              style={{ width: "100%" }}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    {filtersOption?.aging && (
                      <>
                        <div
                          className="mt-1 mb-0 w-20"
                          style={{ marginRight: 10 }}
                        >
                          <FormSelect
                            name="aging"
                            required={true}
                            hideRequiredMark={true}
                            label="Aging Filter"
                            placeholder="Select Aging"
                            options={[
                              { label: "1 Interval", value: 1 },
                              ...Array.from({ length: 11 }, (_, i) => ({
                                label: i + 2 + " Intervals",
                                value: i + 2,
                              })),
                            ]}
                          />
                        </div>
                        <div
                          className="mt-1 mb-0 w-20"
                          style={{ marginRight: 10 }}
                        >
                          <FormSelect
                            name="intervalOfDays"
                            required={true}
                            hideRequiredMark={true}
                            label="Interval Of Days Filter"
                            placeholder="Select Interval Of Days"
                            options={[
                              { label: "1 day", value: 1 },
                              ...Array.from({ length: 29 }, (_, i) => ({
                                label: i + 2 + " days",
                                value: i + 2,
                              })),
                            ]}
                          />
                        </div>
                      </>
                    )}
                  </form>
                </FormProvider>
              </div>
              <div className="content clearfix">
                {loading == true ? (
                  <>
                    <Flex align="center" gap="middle" className="pageLoader">
                      <Spin size="large" className="m-auto" />
                    </Flex>
                  </>
                ) : dataSource?.length > 0 ? (
                  <div
                    style={{
                      width: screenSize - 100 + "px",
                      margin: "0px auto",
                    }}
                  >
                    <Table
                      className="customTable"
                      columns={
                        filtersOption?.aging
                          ? [...columns, ...getAgingIntervalColumns()]
                          : columns
                      }
                      dataSource={
                        filtersOption?.aging
                          ? dataSource?.map((oldObj: any) => {
                              return {
                                ...oldObj,
                                ...oldObj?.agingDetails,
                              };
                            })
                          : dataSource
                      }
                      bordered
                      scroll={{
                        x: filtersOption?.aging
                          ? (extra?.tableScrollX || 0) + aging * 150
                          : extra?.tableScrollX || 1500,
                      }}
                      size="middle"
                      pagination={pagination}
                      onChange={(newPagination, filters, sorter, extra) => {
                        formatParamsForFetchData({
                          aging,
                          intervalOfDays,
                          customer,
                          dateRange,
                          customDateRange,
                          pagination: {
                            ...pagination,
                            current: newPagination.current,
                            pageSize: newPagination.pageSize,
                          },
                        });
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Empty description="No Data Found " />
                  </div>
                )}
              </div>
            </div>

            {/* content end here */}
          </div>
        </Card>
      </Content>
    </>
  );
}

export default DefaultReportTemplate;
