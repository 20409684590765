import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";


function CustomerBalancesReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Customer",
      dataIndex: "customerName",
      key: "Customer Name",
      fixed: "left",
      // width: 250,
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/customer-balances-detail-report/${record?.customerId}`,{
                state: {
                  name: record?.customerName
                }
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoiced Amount",
      dataIndex: "invoicedAmount",
      key: "invoicedAmount",
      width: 200,
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      width: 200,
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Credit Note",
      dataIndex: "creditNote",
      key: "creditNote",
      width: 150,
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Balance Receivables",
      dataIndex: "balanceReceivables",
      key: "balanceReceivables",
      width: 200,
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Overdue Receivables",
      dataIndex: "overdueReceivables",
      key: "overdueReceivables",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
           <Tooltip title={formatCurrency(text?.toString(),record?.currencyCode)+" ("+Number(record?.overdueRecPercentage)?.toFixed(2)+"%)"}>
            {formatCurrency2(text?.toString(), record?.currencyCode)}{"("}{Number(record?.overdueRecPercentage)?.toFixed(2)}{"%)"}
            <Icon name="info" style={{ marginLeft: 8, fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
    },
   
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Customer Balances "
      moduleKey="customerBalancesReport"
      columns={columns}
      filtersOption={{
        customer: true,
        dateRange: true,
        aging: false,
      }}
      previousPath="/view-all-receivables-reports"
      extra={{
        tableScrollX: 1300,
      }}
    />
  );
}

export default CustomerBalancesReport;
