import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";
import { useLocation, useNavigate, useParams } from "react-router";
import FormatDate from "../../masters/DateFormater";


function InvoicesForSalesByCustomerReport() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const name = location.state?.name; 

  const columns: any = [
    {
      key: "orderId",
      title: "Order #",
      dataIndex: "orderId",
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/purchase-order-report-preview/${record.orderNumber}`, {
                state: {
                  previousPath: `/invoices-for-sales-by-customer-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width: 100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
   {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/invoices-for-sales-by-customer-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      width: 100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Amount Without Tax",
      dataIndex: "amountWithoutTax",
      // width:150,
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      // width:150,
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle={`${name} - Invoices Sales, Detail Report`}
      moduleKey="invoicesForSalesByCustomerReport"
      columns={columns}
      filtersOption={{
        customer: false,
        dateRange: false,
        aging: false,
      }}
      previousPath="/sales-by-customer-report"
      extra={{
        tableScrollX: 1000,
        id
      }}
    />
  );
}

export default InvoicesForSalesByCustomerReport;
