import React, { useState, useEffect, FC, useCallback } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Card } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
} from "../../../components/Component";
import { Col } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal, ModalBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import materialsApis from "../../../api/master/materials";
import FormSelect from "../../form-components/SelectComponent";
import FormatDate from "../DateFormater";
import supplierApis from "../../../api/master/supplier";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import misc from "../../../api/misc/dropDowns";
import { Material } from "./types/Material";
import adminOrgApis from "../../../api/master/adminOrg";
import FormTextArea from "../../form-components/TextAreaComponent";
import { useSelector } from "react-redux";
interface SelectOptions {
  label: string;
  value: string;
}
const ViewMaterialDetails: FC = () => {
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { materialId } = useParams();
  const [data, setData]: any = useState();
  const [modalGI, setGIModal] = useState(false);

  const [modalEditBlockName, setModalEditBlockName]: any = useState("");

  const [currencyOptions, setCurrencyOptions] = useState<SelectOptions[]>([]);

  const [formData, setFormData]: any = useState({
    materialType: "",
    productCode: "",
    productName: "",
    description: "",
    category: "",
    unitPrice: 0,
    hsnCode: "",
    sgstRate: 0,
    cgstRate: 0,
    igstRate: 0,
    quantityOnHand: 0,
    quantityAsOfDate: "",
    minimumStockLevel: 0,
    maximumStockLevel: 0,
    reorderPoint: 0,
    unitOfMeasure: "",
    specification: "",
    expenseType: "",
    productLife: 0,
    status: "",
    subcategory: "",
    currency: "",
  });
  const methods = useForm<Material>({
    mode: "onChange",
    defaultValues: {
      materialType: "",
      productCode: "",
      productName: "",
      description: "",
      category: "",
      unitPrice: 0,
      hsnCode: "",
      quantityOnHand: 0,
      quantityAsOfDate: "",
      minimumStockLevel: 0,
      maximumStockLevel: 0,
      reorderPoint: 0,
      unitOfMeasure: "",
      specification: "",
      expenseType: "",
      productLife: 0,
      status: "",
      document: "",
      image: "",
      gst: "",
    },
  });
  const { handleSubmit, watch, setValue } = methods;
  const [docs, setDocs] = useState<any>([]);
  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const imageWatch = watch("image");
  const documentWatch = watch("document");
  const [gstData, setgstData] = useState<SelectOptions[]>([]);

  const fetchGSTSlabs = async () => {
    try {
      const { data } = await misc.getTaxSlabs();
      if (data) {
        setgstData(
          data.map((obj: any) => ({
            label: obj.label,
            value: obj.value.toString(),
          }))
        );
      }
    } catch (error) {}
  };

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = materialId;
    if (id !== undefined || null || "") {
      fetchMaterialsData(id);
      fetchCurrencyData();
      getContructionsMaterialTypes();
      fetchSupplierCategory();
      fetchGSTSlabs();
      fetchUOMOPtions();
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [materialId]);

  const openEditModal = (modalEditBlockName: any) => {
    setModalEditBlockName(modalEditBlockName);
    setGIModal(true);
  };

  const fetchUOMOPtions = async () => {
    try {
      const { data, status, message } = await adminOrgApis.fetchUom();
      if (status) {
        setUomOptions(
          data.map((item: any) => {
            return {
              label: item.uom_name,
              value: item.id.toString(),
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const preventInvalidChars = (event: any) => {
    if (["e", "E", "-", "+"].includes(event.key)) {
      event.preventDefault();
    }
  };

  const submitUpdates = async (submitData: any) => {
    const formData: any = new FormData();
    if (submitData?.image || submitData?.document) {
      const filesData = [];
      const deletedFileData: any = [];
      const files = [];

      if (submitData?.image && submitData?.image[0]) {
        filesData.push({
          indexOfFileLocated: filesData.length,
          module: "material",
          purpose: "image",
          level: "Header Level",
          uniqueId: "",
          action: "Create",
        });
        files.push(submitData.image[0]);
        const previousDoc = docs.filter(
          (document: any) => document.moduleFileType == "image"
        );

        if (previousDoc) {
          previousDoc.forEach((doc: any) => {
            deletedFileData.push({
              docId: doc.docId,
            });
          });
        }
      }
      if (submitData?.document && submitData?.document[0]) {
        filesData.push({
          indexOfFileLocated: filesData.length,
          module: "material",
          purpose: "document",
          level: "Header Level",
          uniqueId: "",
          action: "Create",
        });
        files.push(submitData.document[0]);
        const previousDoc = docs.filter(
          (document: any) => document.moduleFileType == "document"
        );
        if (previousDoc) {
          previousDoc.forEach((doc: any) => {
            deletedFileData.push({
              docId: doc.docId,
            });
          });
        }
      }
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: filesData,
          deletedFileData: deletedFileData,
          updatedFileData: [],
        })
      );
      files.forEach((file) => {
        formData.append(`files`, file);
      });
    } else {
      formData.append("files", JSON.stringify([]));
      formData.append(
        "filesData",
        JSON.stringify({
          newfiledata: [],
          deletedFileData: [],
          updatedFileData: [],
        })
      );
    }
    delete submitData.image;
    delete submitData.document;
    submitData["suppliers"] = submitData?.suppliers?.map((item: any) => {
      delete item["id"];
      return item;
    });

    formData.append("materialData", JSON.stringify(submitData));
    setValue("image", null);
    setValue("document", null);
    const { status, message } = await materialsApis.updateProductById(
      materialId,
      formData
    );
    if (status) {
      Swal.fire("Updated!", "Material has been updated", "success");
      const id = materialId;
      setGIModal(false);
      fetchMaterialsData(id);
    } else {
      Swal.fire("Failed!", message, "error");
    }
  };

  const fetchMaterialsData = async (id: any) => {
    try {
      const { data, status, message } = await materialsApis.fetchProductById(
        id
      );
      if (status) {
        const newData = data;
        setDocs(data.docs);
        setData(newData);
        setFormData({
          materialType: newData.materialType,
          productCode: newData.productCode,
          productName: newData.productName,
          description: newData.description,
          category: newData.category,
          unitPrice: newData.unitPrice,
          hsnCode: newData.hsnCode,
          gst: newData.gst,
          unitOfMeasure: newData.unitOfMeasure,
          specification: newData.specification,
          productLife: newData.productLife,
          status: newData.status,
        });
        setValue("materialType", newData.materialType);
        setValue("productCode", newData.productCode);
        setValue("productName", newData.productName);
        setValue("description", newData.description);
        setValue("category", newData.category);
        setValue("unitPrice", newData.unitPrice);
        setValue("hsnCode", newData.hsnCode);
        setValue("gst", newData.gst);
        setValue("unitOfMeasure", newData.unitOfMeasure);
        setValue("specification", newData.specification);
        setValue("productLife", newData.productLife);
        setValue("status", newData.status);
      }
    } catch (err: any) {
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  const [industryTypes, setIndustryTypes] = useState<SelectOptions[]>([]);
  const [categories, setCategories] = useState<SelectOptions[]>([]);

  const fetchSupplierCategory = async () => {
    try {
      const { data, status, message } =
        await supplierApis.fetchSupplierCategory();
      if (status) {
        // console.log(data);
        setCategories(
          data.map((obj: any) => ({ label: obj.category, value: obj.id }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  const getContructionsMaterialTypes = useCallback(async () => {
    try {
      const matCats = await supplierApis.getContructionsMaterialTypes();
      // console.log(matCats);
      if (matCats.status && matCats.data.length > 0) {
        const transformedData = matCats.data.map((item: any) => ({
          value: item.material_id,
          label: item.name,
        }));
        setIndustryTypes(transformedData);
      }
    } catch (error: any) {
      console.error("Failed to fetch org types:", error.message);
    }
  }, []);

  const fetchCurrencyData = async () => {
    try {
      const res = await misc.getCurrencyTypes();
      const { data, status, message } = res;
      if (status) {
        // setAllCurrencyData(data);
        let currencyOptions = data.map((item: any) => {
          return {
            label: item.currency_code,
            value: item.currency_code,
          };
        });
        setCurrencyOptions(currencyOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <React.Fragment>
      <Head title="Material Details"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Material Details -{" "}
                    <strong className="text-primary small">
                      {data.productName}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <Link to={`${process.env.PUBLIC_URL}/materails`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col lg="5">
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Product Information</BlockTitle>
                        <p>Name, Address etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() => openEditModal("ProductInformation")}
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Material Code</div>
                          <div className="data-value">{data.productCode}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Material Type</div>
                          <div className="data-value">
                            {
                              industryTypes.find(
                                (option: SelectOptions) =>
                                  option.value == data.materialType
                              )?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Category</div>
                          <div className="data-value">
                            {
                              categories.find(
                                (option: SelectOptions) =>
                                  option.value == data.category
                              )?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Material Name</div>
                          <div className="data-value">{data.productName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Unit Of Measure</div>
                          <div className="data-value">
                            {data.unitOfMeasureName}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Specification</div>
                          <div className="data-value">{data.specification}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Product Life</div>
                          <div className="data-value">{data.productLife}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Description</div>
                          <div className="data-value">{data.description}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>

                <Col lg="7">
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">GST Information</BlockTitle>
                        <p>GST information</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() => openEditModal("GSTInformation")}
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Unit Price</div>
                          <div className="data-value">{data.unitPrice}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">HSN Code</div>
                          <div className="data-value">{data.hsnCode}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">GST Rate</div>
                          <div className="data-value">
                            {gstData.find((s) => s.value == data.gst)?.label}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Images and Documents</BlockTitle>
                        <p>Uploaded Images and Documents</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        {allowedActions?.edit ? (
                          <Button
                            outline
                            className="d-none d-sm-inline-flex"
                            style={{ marginRight: "10px" }}
                            onClick={() => openEditModal("ImagesandDocuments")}
                          >
                            <Icon name="edit-alt-fill"></Icon>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      {docs?.map((document: any) => {
                        return (
                          <li className="data-item">
                            <div className="data-col">
                              <div className="data-label">
                                {document.moduleFileType}
                              </div>
                              <span className="data-value">
                                <a
                                  href={document.filePath}
                                  target="_blank"
                                  style={{
                                    pointerEvents: "auto",
                                    cursor: "default",
                                  }}
                                >
                                  {document.fileName}
                                </a>
                              </span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>

              {modalEditBlockName === "ProductInformation" && (
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(submitUpdates)}>
                      <div className="">
                        <div className="nk-modal-head">
                          <h5 className="title">Edit Product Information </h5>
                        </div>
                        <div className="nk-tnx-details mt-sm-3">
                          <Row className="gy-4">
                            <Col md="3">
                              <div className="form-group">
                                <FormInput
                                  name="productCode"
                                  label="Material Code"
                                  maxLength={20}
                                  type="text"
                                  pattern={/^[a-zA-Z0-9\s]*$/}
                                  placeholder="Enter Material Code"
                                  required={true}
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <FormSelect
                                name="materialType"
                                label="Material Type"
                                required={true}
                                options={industryTypes}
                              />
                            </Col>
                            <Col md="3">
                              <FormSelect
                                name="category"
                                label="Category"
                                required={true}
                                options={categories}
                              />
                            </Col>
                            <Col md="3">
                              <div className="form-group">
                                <FormInput
                                  name="productName"
                                  label="Material Name"
                                  type="text"
                                  placeholder="Enter Material Name"
                                  required={true}
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <FormSelect
                                name="unitOfMeasure"
                                label="Unit of Measure"
                                required={true}
                                options={uomOptions}
                              />
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="specification"
                                label="Specification"
                                type="text"
                                required={true}
                                placeholder="Enter Specification"
                              />
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="productLife"
                                label="Material Life (in days)"
                                type="number"
                                onKeyDown={preventInvalidChars}
                                pattern={/^[0-9\s]*$/}
                                placeholder="Enter Material Life"
                              />
                            </Col>
                            <Col md="12">
                              <FormTextArea
                                name="description"
                                label="Description"
                                placeholder="Enter Description"
                                required={true}
                              />
                            </Col>
                            <Col md="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                    onClick={() => submitUpdates(data)}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              )}

              {modalEditBlockName === "GSTInformation" && (
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(submitUpdates)}>
                      <div className="">
                        <div className="nk-modal-head">
                          <h5 className="title">Edit GST Information </h5>
                        </div>
                        <div className="nk-tnx-details mt-sm-3">
                          <Row className="gy-4">
                            <Col md="4">
                              <FormInput
                                name="unitPrice"
                                label="Unit Price"
                                type="number"
                                placeholder="Enter Unit Price"
                              />
                            </Col>
                            <Col md="4">
                              <FormInput
                                name="hsnCode"
                                label="HSN Code"
                                type="text"
                                placeholder="Enter HSN Code"
                              />
                            </Col>
                            <Col md="4">
                              <FormSelect
                                name="gst"
                                label="GST"
                                options={gstData}
                                placeholder="Enter GST"
                              />
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col size="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="md"
                                    type="submit"
                                    // onClick={() => submitForm()}
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              )}

              {modalEditBlockName === "ImagesandDocuments" && (
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(submitUpdates)}>
                      <div className="">
                        <div className="nk-modal-head">
                          <h5 className="title">
                            Edit Preferred Suppliers Information{" "}
                          </h5>
                        </div>
                        <div className="nk-tnx-details mt-sm-3">
                          <Row className="gy-4">
                            <Col md="6">
                              <FormInput
                                name="image"
                                label="Upload Material Image"
                                type="file"
                              />
                              {imageWatch && <div>{imageWatch[0]?.name}</div>}
                            </Col>
                            <Col md="6">
                              <FormInput
                                name="document"
                                label="Upload Material Document"
                                type="file"
                              />
                              {documentWatch && (
                                <div>{documentWatch[0]?.name}</div>
                              )}
                            </Col>
                            <Col md="12">
                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                <li>
                                  <Button
                                    color="primary"
                                    size="lg"
                                    type="submit"
                                  >
                                    Update
                                  </Button>
                                </li>
                                <li>
                                  <a
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setGIModal(false);
                                    }}
                                    className="link link-light"
                                  >
                                    Cancel
                                  </a>
                                </li>
                              </ul>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default ViewMaterialDetails;
