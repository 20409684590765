import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Card } from "reactstrap";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import DispatchComponent from "../../dispatch/dispatchComponent";

function GRNPreview() {
  const location = useLocation();
  const previousState = location.state;
  let { id }: any = useParams();

  return (
    <>
      <Head title="Material Invoice" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <DispatchComponent
              dispatchNumber={id}
              previousPath={previousState?.previousPath}
              extra={{
                reduceTableWidth: 130,
                previousState,
              }}
            />
          </div>
        </Card>
      </Content>
    </>
  );
}
export default GRNPreview;
