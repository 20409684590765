// import React, { useState, useEffect, FC } from "react";
// import { Link, useLocation } from "react-router-dom";
// import { Icon, UserAvatar } from "../../components/Component"; // Assuming these are properly typed elsewhere

// interface DispatchRequestAsideProps {
//     updateSm: (value: boolean) => void;  // Function to update state
//     sm: boolean;  // Boolean state
// }

// const DispatchAside: FC<DispatchRequestAsideProps> = ({ updateSm, sm }) => {
   
//     const location = useLocation();

//     useEffect(() => {
//         sm ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
//     }, [sm]);

//     const isActive = (path: string): string => {
//         return location.pathname === path ? "active" : "";
//     };

//     return (
//         <div className="card-inner-group">
//             <div className="card-inner">
//                 <div className="user-account-info py-0">
//                     <h6 className="overline-title-alt" style={{fontSize: 14 }}>Dispatch</h6>
                   
//                 </div>
//             </div>
//             <div className="card-inner p-0">
//                 <ul className="link-list-menu">
//                     <li onClick={() => updateSm(false)}>
//                         <Link to="/view-dispatch-invoice-list" className={isActive(`${process.env.PUBLIC_URL}/view-dispatch-invoice-list`)}>
//                             <Icon name="eye-fill" />
//                             <span>Create Dispatch</span>
//                         </Link>
//                     </li>
//                     <li onClick={() => updateSm(false)}>
//                         <Link to="/view-dispatch-list" className={isActive(`${process.env.PUBLIC_URL}/view-dispatch-list`)}>
//                             <Icon name="eye-fill" />
//                             <span>View Dispatch</span>
//                         </Link>
//                     </li>
//                 </ul>
//             </div>
//         </div>
//     );
// };

// export default DispatchAside;

import React, { useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../components/Component"; // Assuming these are properly typed elsewhere
import { useSelector } from "react-redux";
import { RootState } from "../../store/store"; // Adjust this import based on your store configuration

interface DispatchRequestAsideProps {
    updateSm: (value: boolean) => void; // Function to update state
    sm: boolean; // Boolean state
}

const DispatchAside: FC<DispatchRequestAsideProps> = ({ updateSm, sm }) => {
    const { data: allowedRoutes }: any = useSelector(
        (state: RootState) => state.allowedRoutes
    );
    const location = useLocation();

    useEffect(() => {
        sm
            ? document.body.classList.add("toggle-shown")
            : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    const LinkTo = ({ path }: { path: string }) => {
        if (!allowedRoutes.includes(path)) {
            return null;
        }
        switch (path) {
            case "view-dispatch-invoice-list":
                return (
                    <Link
                        to="/view-dispatch-invoice-list"
                        className={isActive(`${process.env.PUBLIC_URL}/view-dispatch-invoice-list`)}
                    >
                        <Icon name="eye-fill" />
                        <span>Create Dispatch</span>
                    </Link>
                );
            case "view-dispatch-list":
                return (
                    <Link
                        to="/view-dispatch-list"
                        className={isActive(`${process.env.PUBLIC_URL}/view-dispatch-list`)}
                    >
                        <Icon name="eye-fill" />
                        <span>View Dispatch</span>
                    </Link>
                );
            default:
                return null;
        }
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{ fontSize: 14 }}>
                        Dispatch
                    </h6>
                </div>
            </div>
            <div className="card-inner p-0">
                <ul className="link-list-menu">
                    {[
                        "view-dispatch-invoice-list",
                        "view-dispatch-list",
                    ].map((item) => (
                        <li key={item} onClick={() => updateSm(false)}>
                            <LinkTo path={item} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DispatchAside;
