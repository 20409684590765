import React, { useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../components/Component"; // Assuming these are properly typed elsewhere
import { useSelector } from "react-redux";
import { RootState } from "../../store/store"; // Adjust this import based on your store configuration

interface PaymentsNavProps {
    updateSm: (value: boolean) => void; // Function to update state
    sm: boolean; // Boolean state
}

const PaymentsNav: FC<PaymentsNavProps> = ({ updateSm, sm }) => {
    const { data: allowedRoutes }: any = useSelector(
        (state: RootState) => state.allowedRoutes
    );
    const location = useLocation();

    useEffect(() => {
        sm
            ? document.body.classList.add("toggle-shown")
            : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    const LinkTo = ({ path }: { path: string }) => {
        if (!allowedRoutes.includes(path)) {
            return null;
        }
        switch (path) {
            case "pending-payment-approvals":
                return (
                    <Link
                        to="/pending-payment-approvals"
                        className={isActive(`${process.env.PUBLIC_URL}/pending-payment-approvals`)}
                    >
                        <Icon name="activity-round-fill" />
                        <span>Pending Approvals</span>
                    </Link>
                );
            case "view-payments":
                return (
                    <Link
                        to="/view-payments"
                        className={isActive(`${process.env.PUBLIC_URL}/view-payments`)}
                    >
                        <Icon name="eye-fill" />
                        <span>View Receipts</span>
                    </Link>
                );
            default:
                return null;
        }
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{ fontSize: 14 }}>
                        Payment Receivables
                    </h6>
                </div>
            </div>
            <div className="card-inner p-0">
                <ul className="link-list-menu">
                    {[
                        "pending-payment-approvals",
                        "view-payments",
                    ].map((item) => (
                        <li key={item} onClick={() => updateSm(false)}>
                            <LinkTo path={item} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PaymentsNav;
