import makeApiCall from "../master/axiosIndex";

const reportApis = {
  fetchAllCustomersBySupplier: (): Promise<any> => {
    return makeApiCall({
      method: "get",
      url: "/seller/fetchAllCustomersBySupplier",
    });
  },
  // Sales Report Start --------------------------------
  salesByCustomerReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/salesByCustomerReport",
      data,
    });
  },
  invoicesForSalesByCustomerReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/invoicesForSalesByCustomerReport",
      data,
    });
  },
  salesByMaterialReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/salesByMaterialReport",
      data,
    });
  },
  invoicesForSalesByMaterialReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/invoicesForSalesByMaterialReport",
      data,
    });
  },
  salesInvoiceDetailsReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/salesInvoiceDetailsReport",
      data,
    });
  },
  // Sales Report End --------------------------------

  //  Receivables Report Start --------------------------------
  customerBalancesReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/customerBalancesReport",
      data,
    });
  },
  customerBalancesDetailReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/customerBalancesDetailReport",
      data,
    });
  },
  
  receivablesAgingSummaryReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/receivablesAgingSummaryReport",
      data,
    });
  },
  receivablesAgingSummaryDetailReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/receivablesAgingSummaryDetailReport",
      data,
    });
  },

  paymentsReceivedByCustomerReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/paymentsReceivedByCustomerReport",
      data,
    });
  },
  paymentsReceivedByCustomerDetailReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/paymentsReceivedByCustomerDetailReport",
      data,
    });
  },

  deliveryNoteByCustomerReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/DeliveryNoteByCustomerReport",
      data,
    });
  },

  deliveryNoteByCustomerDetailReport: (data: any) => {
    return makeApiCall({
      method: "post",
      url: "/reports/DeliveryNoteByCustomerDetailReport",
      data,
    });
  },

  //  Receivables Report End--------------------------------
};

export default reportApis;
