import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import dayjs from "dayjs";
import FormatDate from "../../masters/DateFormater";
import { TooltipComponent } from "../../../components/Component";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";
import { useLocation, useNavigate, useParams } from "react-router";


function PaymentsReceivedByCustomerDetailReport() {
   const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const name = location.state?.name; 

    const columns: any = [
     {
      key: "orderId",
      title: "Order #",
      dataIndex: "orderId",
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/purchase-order-report-preview/${record.orderNumber}`, {
                state: {
                  previousPath: `/payments-received-by-customer-detail-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/payments-received-by-customer-detail-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    }, 
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstandingAmount",
      key: "outstandingAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
 
  ];
  return (
    <DefaultReportTemplate
      pageTitle={`${name} - Payments, Detail Report`}
      moduleKey="paymentsReceivedByCustomerDetailReport"
      columns={columns}
      filtersOption={{
        customer: false,
        dateRange: false,
        aging: false,
      }}
      previousPath="/payments-received-by-customer-report"
      extra={{
        tableScrollX: 1100,
        id
      }}
    />
  );
}
export default PaymentsReceivedByCustomerDetailReport