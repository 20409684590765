import dayjs from "dayjs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import QRCode from "qrcode-generator";

export const generateEwayBillPDF = async (signedQRCode: string, data: any) => {
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;

  // Decode JWT and extract data
  const base64Payload = signedQRCode.split(".")[1];
  const payload = JSON.parse(atob(base64Payload));
  const qrData = JSON.stringify(payload.data); // Converting the data field into a string for QR code generation

  // Generate QR Code using qrcode-generator
  const qr = QRCode(0, "L"); // Error correction level L (low)
  qr.addData(qrData);
  qr.make();

  // Retrieve the QR code as a base64 image URL
  const qrCodeData = qr.createDataURL(4); // 4 is the scale factor for higher resolution

  // Document Title
  // doc.setFontSize(10);
  // doc.text("E-Way Bill System", pageWidth / 2, 10, { align: "center" });
  doc.setFontSize(8);
  doc.text("e-Way Bill", pageWidth / 2, 10, { align: "center" });

  // Add QR Code below the header
  doc.addImage(qrCodeData, "PNG", pageWidth / 2 - 15, 15, 30, 30); // Centered below header

  // Table Settings
  let startY = 50; // Adjusted startY to make space for QR code
  const startX = 10;
  const cellPadding = 5;
  const rowHeight = 8;

  // Column Widths
  const columnWidthTitle = 60;
  const columnWidthValue = pageWidth - startX * 2 - columnWidthTitle;

  const createRow = (title: string, value: string) => {
    // Set fonts and styles for title and value
    doc.setFont("helvetica", "bold");
    doc.text(title, startX + cellPadding, startY + rowHeight / 2 + 2);

    doc.setFont("helvetica", "normal");

    // Define widths and heights
    const availableWidth = columnWidthValue - cellPadding * 2;
    const wrappedLineHeight = rowHeight * 0.8; // Reduced height for wrapped lines

    // Split value text to fit within cell width
    const splitValue = doc.splitTextToSize(value, availableWidth);

    // Calculate total cell height based on the number of lines
    const totalHeight = rowHeight + wrappedLineHeight * (splitValue.length - 1);

    // Draw borders for title and value cells, adjusting height based on line count
    doc.rect(startX, startY, columnWidthTitle, totalHeight);
    doc.rect(startX + columnWidthTitle, startY, columnWidthValue, totalHeight);

    // Print each line of wrapped text
    splitValue.forEach((line: any, index: any) => {
      const lineY =
        startY +
        rowHeight / 2 +
        2 +
        index * (index === 0 ? rowHeight : wrappedLineHeight);
      doc.text(line, startX + columnWidthTitle + cellPadding, lineY);
    });

    // Move startY down by the total height of this cell
    startY += totalHeight;
  };

  // Helper function to create a section header spanning both columns
  const createSectionHeader = (headerText: string) => {
    doc.setFontSize(10);
    doc.setFont("helvetica", "bold");
    doc.rect(startX, startY, columnWidthTitle + columnWidthValue, rowHeight); // Full-width cell border
    doc.text(headerText, startX + cellPadding, startY + rowHeight / 2 + 2); // Header text
    startY += rowHeight; // Move to the next row
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
  };

  // Main Table Content

  // Basic Information Rows
  createRow("E-Way Bill No", data?.ewbNo);
  createRow(
    "E-Way Bill Date",
    dayjs(data?.ewbDate)?.format("DD-MMM-YYYY hh:mm A")
  );
  createRow("Generated By", data?.supplierGSTIN + " - " + data?.supplierName);
  createRow("Valid From", dayjs(data?.EwbDt)?.format("DD-MMM-YYYY hh:mm A"));
  createRow("Valid Until", dayjs(data?.EwbValidTill)?.format("DD-MMM-YYYY hh:mm A"));
  createRow("IRN", data?.Irn);

  // Part - A Section
  createSectionHeader("Part - A");
  createRow(
    "GSTIN of Supplier",
    data?.supplierGSTIN + " - " + data?.supplierName
  );
  createRow("Place of Dispatch", data?.placeOfDispatch);
  createRow("GSTIN of Recipient", data?.buyerGSTIN + ", " + data?.buyerName);
  createRow("Place of Delivery", data?.placeOfDelivery);
  createRow("Document No.", data?.invoiceId);// invoice id 
  createRow("Document Date", dayjs(data?.invoiceDate)?.format("DD-MMM-YYYY"));// invoice date
  // createRow("Transaction Type", "Bill To - Ship To");//
  createRow("Value of Goods", data?.invoiceAmount);// invoice amount 
  // createRow("HSN Code", "72142090 - RADHA TMT STEEL 550D LRF 8 MM (+3)");
  createRow("Reason for Transportation", "Supply");
  // createRow("Transporter", "36AADCR6508A1ZQ & RADHA SMELTERS PRIVATE LIMITED");

  // Part - B Section
  // createSectionHeader("Part - B");
  // createRow("Mode", "Road");
  // createRow("Vehicle / Transport", "TS07UM5575");
  // createRow("Place of Dispatch", "Gandipet, Hyderabad");
  // createRow("Date & Time", "17/09/2024 10:08 AM");
  // createRow("Entered By", "36AAOCP7271P1ZG");
  // createRow("CEWB No.", "181939611896");

  // Footer Note
  // doc.setFontSize(9);
  // doc.text(
  //   "Note*: If any discrepancy in information please try after sometime.",
  //   startX,
  //   startY + rowHeight + 5
  // );

  // Save PDF
  // doc.save('EwayBill.pdf');

  const pdfBlob = doc.output("blob");
  const pdfUrl = URL.createObjectURL(pdfBlob);
  return { pdfUrl, pdfBlob };
};
