import React from "react";
import EnglishFlag from "../../../../images/flags/english-sq.png";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { LangDropdown, UserAvatar } from "../../../../components/Component";
import { useSelector } from "react-redux";

const LanguageHead = () => {
  const { data, loading, error } = useSelector((state) => state.currentUser);
   const logoUrl = (orgLogo) => {
    try {
      if (typeof orgLogo === "string") {
        return orgLogo;
      } else {
        return orgLogo[0];
      }
    } catch (error) {}
  };
  return (
    <UncontrolledDropdown className="language-dropdown">
      <img src={logoUrl(data?.orgLogo)} alt={""} height={36} />
      {/* <DropdownToggle
        tag="a"
        href="#toggle"
        onClick={(ev) => ev.preventDefault()}
        className="dropdown-toggle nk-quick-nav-icon"
      >
        </DropdownToggle> */}
        {/* <UserAvatar className="border border-light xs" theme={"light"}>
        </UserAvatar> */}
      {/* <LangDropdown className="dropdown-menu-s1" /> */}
    </UncontrolledDropdown>
  );
};

export default LanguageHead;
