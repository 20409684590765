import React, { useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Row, Col, Button, Modal, ModalBody, Badge } from "reactstrap";
import { Block, Icon } from "../../components/Component";
import CropperComponent from "../components/CropperComponent";
import { nanoid } from "nanoid";
import { Form, Input, InputNumber, Space, Table, Tooltip } from "antd";
import FormInput from "../form-components/InputComponent";
import {
  PaperClipOutlined,
  InfoCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusSquareOutlined,
  CalculatorOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { formatCurrency, formatCurrency2 } from "../../utils/Utils";
import invoiceApis from "../../api/Invoice/invoice";
import SignatureModal from "../purchase-orders/SignatureModal";
const Materails: React.FC = (props: any) => {

  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [signature, setSignature] = useState({
    isActive: false,
    label: "Authorised Signatory",
    image: null,
  });
  const [showSignatureModel, setShowSignatureModel] = useState<boolean>(false);
  const [showCropModel, setShowCropModel] = useState<boolean>(false);
  const { control, reset, setValue, watch, handleSubmit } = useFormContext();
  const [fields, setFields] = useState<any>([]);
  const [currency_code, setCurrency_code] = useState<any>("INR");
  const [additionalCharges, setAdditionalCharges] = useState<any>([]);
  const [creditNoteAmount, setCreditNoteAmount] = useState<any>(0);
  const previousFormDate = watch();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  

  const columns: any = [
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
      fixed: "left",
      render: (material: any) => <div className="align-left">{material}</div>,
    },
    {
      title: "Invoiced Qty",
      dataIndex: "quantityInvoiced",
      key: "quantityInvoiced",
      width: 150,
      render: (text: any, record: any) => {
        const isHighlighted = false;
        return (
          <div
            className="align-left"
            style={{
              backgroundColor: isHighlighted ? "lightcoral" : "transparent", // light red color for highlighting
              color: isHighlighted ? "white" : "inherit", // optional: change text color when highlighted
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {Number(text) + " " + record?.uom}
          </div>
        );
      },
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      editable: true,
      render: (text: any, record: any) =>
        text ? (
          <div className="align-left">{Number(text) + " " + record?.uom}</div>
        ) : (
          <></>
        ),
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
      render: (text: any, record: any) => {
        const isHighlighted = Number(record?.orderPrice) < Number(text);
        return (
          <div
            style={{
              backgroundColor: isHighlighted ? "lightcoral" : "transparent", // Highlight if orderPrice < unitPrice
              color: isHighlighted ? "white" : "inherit", // Optional: change text color when highlighted
              padding: "4px",
              borderRadius: "4px",
            }}
          >
            {formatCurrency2(Number(text), "INR")}
            <span>
              <Tooltip title={formatCurrency(Number(text), "INR")}>
                <InfoCircleOutlined style={{ marginLeft: 8, fontSize: 12 }} />
              </Tooltip>
            </span>
          </div>
        );
      },
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 150,
      render: (text: any) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      key: "netAmount",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any,record:any) => `${(Number(record?.sgst) &&Number(record?.cgst))?Number(text)+"%":"N/A"}`,
        },
        {
          title: "Amount",
          dataIndex: "cgst",
          key: "cgst",
          width: 150,
          render: (text:any, record:any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {(Number(record?.sgst) &&Number(record?.cgst))?formatCurrency(text, "INR"):"N/A"}
          </span>
        </div>
      ),
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any,record:any) => `${(Number(record?.sgst) &&Number(record?.cgst))?Number(text)+"%":"N/A"}`,
        },
        {
          title: "Amount",
          dataIndex: "sgst",
          key: "sgst",
          width: 150,
          render: (text:any, record:any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {(Number(record?.sgst) &&Number(record?.cgst))?formatCurrency(text, "INR"):"N/A"}
          </span>
        </div>
      ),
        },
      ],
    },
    {
      title: "IGST",
      children: [
        {
          title: "%",
          dataIndex: "igstPercentage",
          key: "igstPercentage",
          width: 100,
          render: (text: any,record:any) => `${(!Number(record?.sgst) &&!Number(record?.cgst))?Number(text)+"%":"N/A"}`,
        },
        {
          title: "Amount",
          dataIndex: "igst",
          key: "igst",
          width: 150,
          render: (text:any, record:any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {(!Number(record?.sgst) &&!Number(record?.cgst))?formatCurrency(text, "INR"):"N/A"}
          </span>
        </div>
      ),
          
        },
      ],
    }, 
    {
      title: "Gross Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: "Action",
      key: "action",
      width: 80,
      fixed: "right",
      render: (text: any, record: any) => (
        <span className="flex justify-center">
          <Icon
            name="trash"
            className="cursor-pointer"
            onClick={() => handleDelete(record)}
          />
        </span>
      ),
    },
  ];
  const handleDelete = (record: any) => {
    const filteredItems = [...fields]?.filter(
      (item: any) => item.lineNo != record.lineNo
    );
    setFields(filteredItems);
    setCreditNoteAmount(
      filteredItems?.reduce(
        (a: any, b: any) => a + Number(b?.quantity) * Number(b.unitPrice),
        0
      ) ||
        filteredItems?.reduce(
          (a: any, b: any) =>
            a + Number(b?.quantityInvoiced) * Number(b.unitPrice),
          0
        )
    );
    setValue("fields", JSON.stringify(filteredItems));
  };
  const fetchInvoiceDetails = async (invoiceNumber: any) => {
    try {
      const res = await invoiceApis.fetchInvoice(invoiceNumber);
      const { data, status, message } = res;
      if (status) {
        const invoiceLines = data?.invoiceLines;
        setFields(invoiceLines);
        setCreditNoteAmount(
          invoiceLines?.reduce(
            (a: any, b: any) => a + Number(b?.quantity) * Number(b.unitPrice),
            0
          ) ||
            invoiceLines?.reduce(
              (a: any, b: any) =>
                a + Number(b?.quantityInvoiced) * Number(b.unitPrice),
              0
            )
        );
        setCurrency_code(data?.currencyCode);
        setValue("currencyCode", data?.currencyCode);
        setValue("invoiceBillingAddressId", data?.billingAddressId);
        setValue("billingAddress", JSON.stringify(data?.billingAddress));
        setValue("deliveryAddress", JSON.stringify(data?.deliveryAddress));
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const { invoiceNumber, activeInvoiceNumber, signatureObj, fields, notes } =
      previousFormDate;

    if (signatureObj) {
      setSignature(JSON.parse(signatureObj));
    }

    if (previousFormDate?.againstInvoice == "Yes") {
      if (fields && invoiceNumber == activeInvoiceNumber) {
        const _fields = JSON.parse(fields);
        setFields(_fields);
        setCreditNoteAmount(
          _fields?.reduce(
            (a: any, b: any) => a + Number(b?.quantity) * Number(b.unitPrice),
            0
          ) ||
            _fields?.reduce(
              (a: any, b: any) =>
                a + Number(b?.quantityInvoiced) * Number(b.unitPrice),
              0
            )
        );
      } else {
        fetchInvoiceDetails(previousFormDate?.invoiceNumber);
        setValue("activeInvoiceNumber", previousFormDate?.invoiceNumber);
      }
    } else {
      if (notes) {
        const _notes = JSON.parse(notes);
        const uniqueIds: any = [];
        Object.keys(_notes).forEach((key) => {
          const _key = key?.split(".")[1];
          if (!uniqueIds.includes(_key)) {
            uniqueIds.push(_key);
          }
        });
        setAdditionalCharges(uniqueIds?.map((_id: any) => ({ id: _id })));
        Object.keys(_notes).map((key) => {
          form2.setFieldValue(key, _notes[key]);
        });
      }
    }
  }, []);

  useEffect(() => {
    setValue("signatureObj", JSON.stringify(signature));
  }, [signature]);

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }: any) => {
    const handleBlur = (value: number) => {
      console.log("blur", value);

      const updatedFields = fields.map((item: any) =>
        item.lineNo === record.lineNo ? { ...item, [dataIndex]: value } : item
      );
      setCreditNoteAmount(
        updatedFields?.reduce(
          (a: any, b: any) => a + Number(b?.quantity) * Number(b.unitPrice),
          0
        )
      );
      setFields(updatedFields);
      setValue(`fields`, JSON.stringify(updatedFields));
    };

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex + `_${record?.lineNo}`}
            style={{ margin: 0 }}
            rules={[
              {
                type: "number",
                min: 0.01,
                max: Number(record?.quantityInvoiced),
                message: `${title} must be in range 1 to ${Number(
                  record?.quantityInvoiced
                )}`,
              },
            ]}
            initialValue={
              Number(record[dataIndex]) || Number(record["quantityInvoiced"])
            }
          >
            <InputNumber
              defaultValue={
                Number(record[dataIndex]) || Number(record["quantityInvoiced"])
              }
              className="w-100"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
              step={0.01}
              // onChange={(e: any) => {
              //   handleBlur(e);
              // }} // Use onBlur instead of onChange
              onBlur={(e: any) => handleBlur(e?.target?.value)} // Use onBlur instead of onChange
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <>
      <Block>
        <form className="form-validate" onSubmit={handleSubmit(() => {})}>
          <div className="table-responsive poTable">
            {previousFormDate?.againstInvoice == "Yes" ? (
              <>
                <Form form={form} component={false}>
                  <div
                    style={{ width: screenSize - 120 + "px", margin: "auto" }}
                  >
                    <Table
                      className="customTable"
                      columns={columns?.map((col: any) => {
                        if (
                          [
                            "unitPrice",
                            "basicAmount",
                            "netAmount",
                            "grossTotal",
                          ].includes(col?.dataIndex)
                        ) {
                          return {
                            ...col,
                            render: (text: any, record: any) => {
                              return (
                                <div className="justify-end">
                                  {formatCurrency(Number(text), currency_code)}
                                </div>
                              );
                            },
                          };
                        }
                        if (col?.children?.length > 0) {
                          return {
                            ...col,
                            // children: col?.children?.map((_col: any) => {
                            //   if (_col?.title == "Amount") {
                            //     return {
                            //       ..._col,
                            //       render: (text: any, record: any) => {
                            //         return (
                            //           <div className="justify-end">
                            //             {formatCurrency(
                            //               Number(text),
                            //               currency_code
                            //             )}
                            //           </div>
                            //         );
                            //       },
                            //     };
                            //   }
                            //   return _col;
                            // }),
                          };
                        }
                        if (!col.editable) {
                          return col;
                        }
                        return {
                          ...col,
                          onCell: (record: any) => ({
                            record,
                            inputType:
                              col.dataIndex === "quantity" ? "number" : "text",
                            dataIndex: col.dataIndex,
                            title: col.title,
                            editing: true,
                          }),
                        };
                      })}
                      dataSource={fields?.map((field: any) => {
                        return {
                          ...field,
                          key: field.lineNo,
                        };
                      })}
                      scroll={{ x: "max-content" }}
                      bordered
                      size="small"
                      pagination={false}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                    />
                  </div>
                </Form>

                <Row
                  style={{
                    margin: "20px",
                  }}
                >
                  <Col className="justify-end">
                    <div className="w-40">
                      <div className="d-flex justify-content-between">
                        <div className="w-50 justify-end">
                          <strong>Net Amount</strong>
                        </div>
                        <span className="r_align">
                          {formatCurrency(
                            fields?.reduce(
                              (a: any, b: any) =>
                                a +
                                Number(b?.quantityInvoiced) *
                                  Number(b.unitPrice),
                              0
                            ),
                            currency_code
                          )}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="w-50 justify-end">
                          <strong>Credit Note Amount</strong>
                        </div>
                        <span className="r_align">
                          {formatCurrency(creditNoteAmount, currency_code)}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Form form={form2} component={false} requiredMark={false}>
                  <Row className="mt-2 w-75">
                    <div>
                      <div className="d-flex align-items-center">
                        <h6 className="m-1 mb-0">NOTE DETAILS</h6>
                      </div>
                      {additionalCharges.map((field: any, index: number) => (
                        <Row key={index} className="m-2 mb-0 w-100">
                          <div className="w-50 ">
                            <Form.Item
                              name={`AdditionalCharges.${field.id}.name`}
                              label={<strong>Line Name</strong>}
                              rules={[
                                {
                                  required: true,
                                  message: "Name is required",
                                },
                              ]}
                              className="mb-0"
                            >
                              <Input className="w-100" autoComplete="off" />
                            </Form.Item>
                          </div>
                          <div className="w-35">
                            <Form.Item
                              name={`AdditionalCharges.${field.id}.price`}
                              label={<strong>Amount</strong>}
                              rules={[
                                {
                                  required: true,
                                  message: "Amount is required",
                                },
                                 {
                                  type: "number",
                                  min: 1,
                                  message: "Amount should be greater than 0",
                                }
                              ]}
                              className="mb-0"
                            >
                              <InputNumber
                                className="w-100"
                                formatter={(value) =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )
                                }
                                parser={(value: any) =>
                                  value!.replace(/\$\s?|(,*)/g, "")
                                }
                                onKeyPress={(event) => {
                                  if (!/^[0-9]+$/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                autoComplete="off"
                              />
                            </Form.Item>
                          </div>
                          <Col className="d-flex align-items-center w-15 ">
                            <a
                              href="#dropdownitem"
                              onClick={() => {
                                form2.setFieldsValue({
                                  [`AdditionalCharges.${field.id}.name`]: "",
                                  [`AdditionalCharges.${field.id}.price`]: null,
                                });
                                const newAdditionalCharges: any = [];
                                additionalCharges.forEach(
                                  (fld: any, ind: number) => {
                                    if (fld.id != field.id) {
                                      const name = form2.getFieldValue(
                                        `AdditionalCharges.${fld.id}.name`
                                      );
                                      const price = form2.getFieldValue(
                                        `AdditionalCharges.${fld.id}.price`
                                      );
                                      if (name && price) {
                                        newAdditionalCharges.push({
                                          name,
                                          price,
                                          id: fld.id,
                                        });
                                      }
                                    }
                                  }
                                );
                                setAdditionalCharges(newAdditionalCharges);
                              }}
                              className="addNewItem mt-0"
                            >
                              <DeleteOutlined />
                            </a>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </Row>
                  <div className="w-20 m-1 mb-0">
                    <a
                      type={undefined}
                      onClick={async () => {
                        try {
                          const newAdditionalCharges: any =
                            await form2.validateFields();
                          setValue(
                            "notes",
                            JSON.stringify(newAdditionalCharges)
                          );
                          if (
                            additionalCharges.find(
                              (field: any, ind: number) => {
                                const name = form2.getFieldValue(
                                  `AdditionalCharges.${field?.id}.name`
                                );
                                const price = form2.getFieldValue(
                                  `AdditionalCharges.${field?.id}.price`
                                );
                                return !name || !price;
                              }
                            )
                          ) {
                            return;
                          }

                          setAdditionalCharges([
                            ...additionalCharges,
                            {
                              name: "",
                              price: null,
                              id: nanoid(4),
                            },
                          ]);
                        } catch (error) {}
                      }}
                      className="addNewItem mb-0 mt-0 m-4"
                    >
                      <PlusSquareOutlined /> &nbsp; Add Note
                    </a>
                  </div>
                </Form>
              </>
            )}
            <span style={{ display: "none" }}>
              <FormInput name="currencyObj" label="" type="text" />
              <FormInput name="fields" label="" type="text" />
              <FormInput name="signatureObj" label="" type="text" />
            </span>
            <Row
              className={`m-4 justify-content-${
                previousFormDate?.againstInvoice == "Yes" ? "end" : "start"
              }`}
            >
              {signature?.isActive ? (
                <div className="signatureBtnWrapper">
                  <div className="d-flex justify-content-between align-items-center">
                    <strong>Signature</strong>
                    <a
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSignature((prev) => ({
                          ...prev,
                          isActive: false,
                          image: null,
                          label: "Authorised Signatory",
                        }));
                        setValue("signatureObj", {
                          isActive: false,
                          image: null,
                          label: "Authorised Signatory",
                        });
                      }}
                    >
                      <Icon
                        name="cross"
                        className="pointer"
                        onClick={() => {
                          setSignature((prev) => ({
                            ...prev,
                            isActive: false,
                            image: null,
                            label: "Authorised Signatory",
                          }));
                          setValue("signatureObj", {
                            isActive: false,
                            image: null,
                            label: "Authorised Signatory",
                          });
                        }}
                      />
                    </a>
                  </div>
                  <div
                    style={{
                      minHeight: signature?.image ? "0px" : "120px",
                      display: signature?.image ? "block" : "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!signature?.image && (
                      <Button
                        color="btn-dim btn"
                        onClick={() => {
                          setShowCropModel(true);
                        }}
                      >
                        <Icon
                          name="upload-cloud"
                          className="pointer"
                          onClick={() => {
                            setShowCropModel(true);
                          }}
                        />
                        <span>Upload</span>
                      </Button>
                    )}
                    {signature?.image && (
                      <>
                        <div className="signatueImgWrapper border">
                          <img
                            src={signature?.image}
                            alt="Signed"
                            style={{ width: "160px" }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            color="btn-dim btn"
                            onClick={() => {
                              setSignature((pre) => {
                                return { ...pre, image: null };
                              });
                            }}
                          >
                            <span>Reset</span>
                            <Icon
                              name="redo"
                              className="pointer"
                              onClick={() => {
                                setSignature((pre) => {
                                  return { ...pre, image: null };
                                });
                              }}
                            />
                          </Button>
                        </div>
                        <div>
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="signatureLabel"
                            >
                              Signature Label
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="signatureLabel"
                              onChange={(e) => {
                                setSignature((pre) => {
                                  return {
                                    ...pre,
                                    label: e.target.value,
                                  };
                                });
                              }}
                              defaultValue={
                                signature?.label || "Authorised Signatory"
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {!signature?.image && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Button
                          color="btn-dim btn"
                          onClick={() => setShowSignatureModel(true)}
                        >
                          <Icon name="pen" />
                          <span>Use Signature Pad</span>
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    border: "1px solid #e5ecf7",
                    backgroundColor: "#f7faff",
                    padding: "10px",
                    marginLeft: "10px",
                    width: "22%",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  <Button
                    color="btn-dim btn"
                    onClick={() => {
                      setSignature((pre) => {
                        return { ...pre, isActive: true };
                      });
                    }}
                  >
                    <Icon
                      name="edit"
                      className="pointer"
                      onClick={() => {
                        setSignature((pre) => {
                          return { ...pre, isActive: true };
                        });
                      }}
                    />
                    <span>Add Signature</span>
                  </Button>
                </div>
              )}
            </Row>
          </div>
          {showSignatureModel && (
            <SignatureModal
              modal={showSignatureModel}
              toggle={() => setShowSignatureModel(!showSignatureModel)}
              onSave={(data: string) => {
                setSignature((pre: any) => {
                  return { ...pre, image: data };
                });
                setShowSignatureModel(false);
              }}
            />
          )}
          {showCropModel && (
            <CropperComponent
              modal={showCropModel}
              toggle={() => setShowCropModel(!showCropModel)}
              onSave={(data: string) => {
                setSignature((pre: any) => {
                  return { ...pre, image: data };
                });
                setShowCropModel(false);
              }}
            />
          )}
          <div className="actions clearfix">
            <ul>
              <li>
                <Button
                  color="primary"
                  type="submit"
                  onClick={async () => {
                    try {
                      if (previousFormDate?.againstInvoice == "Yes") {
                        const _fields: any = [];
                        const rows = await form.validateFields();
                        fields.forEach((item: any) => {
                          if (Number(rows[`quantity_` + item.lineNo]) > 0) {
                            _fields.push({
                              ...item,
                              quantity: rows[`quantity_` + item.lineNo],
                            });
                          }
                        });
                        setValue("fields", JSON.stringify(_fields));
                        if (_fields.length == 0) {
                          Swal.fire({
                            icon: "info",
                            title: "Material",
                            text: "Please Add Material",
                          });
                          return;
                        }
                      } else {
                        const _notes = await form2.validateFields();
                        console.log(_notes);
                        if (Object.keys(_notes).length == 0) {
                          Swal.fire({
                            icon: "info",
                            title: "Notes",
                            text: "Please Add Notes",
                          });
                          return;
                        } else {
                          setValue("notes", JSON.stringify(_notes));
                        }
                      }
                      if (!signature?.image) {
                        Swal.fire({
                          icon: "info",
                          title: "Signature",
                          text: "Signature is required",
                        });
                        return;
                      }
                      props.next();
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Next
                </Button>
              </li>
              <li>
                <Button
                  color="primary"
                  onClick={props.prev}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </Block>
      {showCropModel && (
        <CropperComponent
          modal={showCropModel}
          toggle={() => setShowCropModel(!showCropModel)}
          onSave={(data: string) => {
            setSignature((pre: any) => {
              return { ...pre, image: data };
            });
            setShowCropModel(false);
          }}
        />
      )}
    </>
  );
};

export default Materails;
