import React, { useEffect, useState, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import { Icon, Row, Button, RSelect, TooltipComponent } from "../../../components/Component";
import {Col} from "reactstrap";
import FormSelect from "../../form-components/SelectComponent";
import materialsApis from "../../../api/master/materials";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { Alert } from "reactstrap";
const ImageDocuments: React.FC = (props: any) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useFormContext();
  const imageWatch = watch("image");
  const document = watch("document");
  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });
  const submitForm = async (data: any) => {
    delete data.image;
    delete data.document;
    const filesData = [];
    const files = [];
    const formData: any = new FormData();
    if (imageWatch[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "material",
        purpose: "Material Image",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(imageWatch[0]);
    }
    if (document[0]) {
      filesData.push({
        indexOfFileLocated: filesData.length,
        module: "material",
        purpose: "Material Document",
        level: "Header Level",
        uniqueId: "",
        action: "Create",
      });
      files.push(document[0]);
    }
    formData.append("materialData", JSON.stringify(data));
    formData.append("filesData", JSON.stringify(filesData));
    files.forEach((file) => {
      formData.append(`files`, file);
    });
    
    const response = await materialsApis.createProduct(formData);
    const { status, message } = response;
    if (status) {
      Swal.fire("Created!", "Material has been created", "success");
      navigate("/materails");
    } else {
      console.log("Error in form submission", message, response);
      let errorMessage = "An error occurred";
      let fullErrorMessage;
      if (typeof message == "string") {
        fullErrorMessage = `${errorMessage}\n${"-"}\n${message}`;
      } else {
        fullErrorMessage = `${errorMessage}\n${"-"}\n${response?.message?.join(
          ", "
        )}`;
      }
      // const fullErrorMessage = `${errorMessage}\n${message.join(", ")}`;
      setAlertInfo({
        type: "error",
        message: fullErrorMessage,
        errors: [],
      });
    }
  };
  useEffect(() => {
    setAlertInfo({
      type: "", // 'success' or 'error'
      message: "",
      errors: [],
    });
  }, []);
  return (
    <>
      {alertInfo.message && (
        <div className="mb-3 mt-1">
          <Alert
            color={alertInfo.type === "error" ? "danger" : "success"}
            className="alert-icon"
          >
            <strong>{alertInfo.message}</strong>
            <Icon
              name={
                alertInfo.type === "error" ? "alert-circle" : "check-circle"
              }
            />
            {alertInfo.errors.length > 0 ? (
              <ul>
                {alertInfo.errors.map((err: any, index: any) => (
                  <li key={index}>{err}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        </div>
      )}
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
        <Col lg="6">
            {imageWatch && imageWatch[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label className="form-label" htmlFor={"image"}>
                    Waste Management Procedures Docs Upload
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`image-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{imageWatch[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("image", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
            <div
              style={{
                display: `${
                  imageWatch && imageWatch[0]?.name ? "none" : "block"
                }`,
              }}
            >
              <FormInput
                name="image"
                label="Image"
                type="file"
                placeholder="Docs Upload"
              />
            </div>
          </Col>
           <Col lg="6">
            {document && document[0]?.name && (
              <div className="form-group">
                <div className="form-label-wrapper">
                  <label className="form-label" htmlFor={"document"}>
                  Document 
                  </label>
                  <TooltipComponent
                    iconClass="card-hint"
                    icon="help-fill"
                    direction="left"
                    id={`document-tooltip`}
                    text="Only PNG, JPG and JPEG files are allowed"
                    containerClassName={""}
                  />
                </div>
                <div
                  className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                  style={{ borderRadius: "3px" }}
                >
                  <span>{document[0]?.name}</span>
                  <span
                    onClick={() => {
                      setValue("document", null);
                    }}
                    style={{
                      cursor: "pointer",
                      marginLeft: "10px",
                      display: "block",
                      fontSize: 14,
                    }}
                  >
                    <Icon name="cross"></Icon> &nbsp;
                  </span>
                </div>
              </div>
            )}
            <div
              style={{
                display: `${
                  document && document[0]?.name ? "none" : "block"
                }`,
              }}
            >
              <FormInput
                name="document"
                label="Document"
                type="file"
                placeholder="Docs Upload"
              />
            </div>
          </Col>
        </Row>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit">
                Submit Form
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={props.prev}
                className="btn-dim btn btn-secondary"
              >
                Previous
              </Button>
            </li>
          </ul>
        </div>
      </form>
    </>
  );
};

export default React.memo(ImageDocuments);
