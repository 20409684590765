import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Select, { SingleValue } from "react-select";
import { Row, Col, Button, Spinner } from "reactstrap";
import { Drawer } from 'antd';
import rolesApi from "../../api/master/roles";

interface Employee {
  name: string;
  id: string;
}

interface Role {
  roleName: string;
  employees: Employee[];
  roleID: any;
  level: string;
}

interface CustomDrawerProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ApprovalData[]) => void;
  setApprovalSubmissionStatus: (status: boolean) => void;
  approvalData: ApprovalData[];
  stateIds:any
}

interface FormValues {
  [key: string]: string;
}

interface ApprovalData {
  roleId: any;
  level: string;
  userId: any;
}

const QuotationApprovalDrawer: React.FC<CustomDrawerProps> = ({ open, onClose, onSubmit, setApprovalSubmissionStatus, approvalData,stateIds }) => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormValues>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
 const fetchApprovalHierarchyUsersByModuleId = async (moduleID: number) => {
    try {
      const res = await rolesApi.fetchHierarchyUsersByModuleId(moduleID,stateIds);
      if (!res) return;
      const { data, status } = res;
      if (status) {
        setRoles(data);
        setRoles(data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const fetchModuleConfig = async () => {
    try {
      const res = await rolesApi.getModuleConfig();
      const moduleID = res?.data?.find(
        (x: any) => x.moduleName == "Invoice"
      )?.moduleID;
      console.log(moduleID);
      await fetchApprovalHierarchyUsersByModuleId(moduleID);
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
     fetchModuleConfig();
  }, []);

  useEffect(() => {
    roles.forEach((role, index) => {
      register(role.level, { required: "This field is required" }); // uncomment this line if you want to show the required field error
    });
  }, [roles, register]);

  useEffect(() => {
    if (approvalData && approvalData.length > 0) {
      approvalData.forEach((data, index) => {
        setValue(data.level, data.userId);
      });
      setLoading(false);
    }
  }, [approvalData, setValue]);

  const submitApprovals: SubmitHandler<FormValues> = (data) => {
    const approvalData: ApprovalData[] = roles.map((role, index) => ({
      roleId: Number(role.roleID),
      level: role.level,
      userId: data[`${role.level}`]
    }));

    onSubmit(approvalData);
    setApprovalSubmissionStatus(true);
  };

  const handleSelectChange = (name: string, value: SingleValue<{ value: string; label: string }>) => {
    if (value && value.value) {
      setValue(name, value.value, { shouldValidate: true });
    }
  };

  const getDefaultValue = (role: Role) => {
    const approval = approvalData?.find(data => data.level === role.level);
    if (approval) {
      const employee = role.employees?.find(emp => emp.id === approval.userId);
      if (employee) {
        return { value: employee.id, label: employee.name };
      }
    }
    return null;
  };

  return (
    <Drawer
      title="Set Invoice Approvals"
      placement="right"
      onClose={onClose}
      open={open}
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Spinner color="primary" />
        </div>
      ) : roles.length > 0 ? (
        <div>
          <p>There are {roles.length} levels of approvals set for this process</p>
          <form onSubmit={handleSubmit(submitApprovals)}>
            {roles.map((role, index) => (
              <Row className="mt-2" key={index}>
                <Col lg="12">
                  <label>{`${role.level} (Role: ${role.roleName})`}</label>
                  <Select
                    classNamePrefix="react-select"
                    options={role.employees?.map((employee) => ({
                      value: employee.id,
                      label: employee.name,
                    }))}
                    onChange={(selectedOption) =>
                      handleSelectChange(`level${index + 1}`, selectedOption)
                    }
                    placeholder="Select"
                    defaultValue={getDefaultValue(role)}
                  />
                  {errors[`${role.level}`] && (
                    <p className="mandatory">{errors[`${role.level}`]?.message}</p>
                  )}
                </Col>
              </Row>
            ))}
            <Button type="submit" color="primary" className="mt-3">
              Save
            </Button>
          </form>
        </div>
      ):
      <p style={{ color: 'red' }}>Approval hierarchy for the Invoice is not set. Please contact the administrator</p> 
      }
    </Drawer>
  );
};

export default React.memo(QuotationApprovalDrawer);
