import React from "react";
import { useNavigate } from "react-router";
import DefaultReportTemplate from "../defaultReportTemplate";
import FormatDate from "../../masters/DateFormater";
import { TooltipComponent } from "../../../components/Component";
import { Icon } from "../../../components/Component";
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";

function PaymentsReceivedByCustomerReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Payment #",
      dataIndex: "paymentId",
      key: "paymentId",
      width: 200,
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(
                `/payments-received-by-customer-detail-report/${record?.trxReference}`,
                {
                  state: {
                    name: record?.customerName,
                  },
                }
              );
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      dataIndex: "trxReference",
      title: "TRX Reference",
      key: "trxReference",
      width: 200,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 120,
      render: (date: any) => <span>{FormatDate(date) || "N/A"}</span>,
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      width: 120,
      render: (date: any) => <span>{FormatDate(date) || "N/A"}</span>,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      fixed: "left",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`customer-tooltip-${index}`}>
              {text?.length > 20 ? `${text!.substring(0, 25)}... ` : text}
            </span>
            {text?.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`customer-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "paymentCreatedByName",
      key: "paymentCreatedByName",
      width: 150,
      render: (text: string) => (
        <span className="text-capitalize">{text || "N/A"}</span>
      ),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      width: 200,
      render: (text: any) => <span className="text-capitalize">{text}</span>,
    },
    {
      title: "Credit Utilized Amount",
      dataIndex: "creditUtilizedAmount",
      key: "creditUtilizedAmount",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <Tooltip
            title={formatCurrency(text?.toString(), record?.currencyCode)}
          >
            {formatCurrency2(text?.toString(), record?.currencyCode)}
            <Icon name="info" style={{ marginLeft: 8, fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Payment Amount",
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      width: 150,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <Tooltip
            title={formatCurrency(text?.toString(), record?.currencyCode)}
          >
            {formatCurrency2(text?.toString(), record?.currencyCode)}
            <Icon name="info" style={{ marginLeft: 8, fontSize: 12 }} />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
    },
  ];

  return (
    <DefaultReportTemplate
      pageTitle="Payments Received By Customer"
      moduleKey="paymentsReceivedByCustomerReport"
      columns={columns}
      filtersOption={{
        customer: true,
        dateRange: true,
      }}
      previousPath="/view-all-receivables-reports"
      extra={{
        tableScrollX: 1500,
      }}
    />
  );
}

export default PaymentsReceivedByCustomerReport;
