import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap";

const InternetStatus: React.FC = () => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleOnline = () => {
      console.log("Is Online:", true); // Log when back online
      setIsOnline(true);
    };

    const handleOffline = () => {
      console.log("Is Online:", false); // Log when offline
      setIsOnline(false);
    };

    // Check the initial online status
    const initialStatus = navigator.onLine;
    console.log("Is Online:", initialStatus); // Log the initial status
    setIsOnline(initialStatus);

    // Add event listeners for online/offline status
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (isOnline) return null;

  return (
    <div
      className="low-internet-screen"
    >
      <Alert
        isOpen={true}
        color="danger"
        className="start-0 w-100 py-1"
        style={{
          top: 0,
          textAlign: "center",
          fontSize: "14px",
          cursor: "default",
        }}
      >
        You are offline. Please check your internet connection.
      </Alert>
    </div>
  );
};

export default InternetStatus;
