
import React from "react";
import { useLocation, useParams } from "react-router";
import InvoiceDetails from "./poInvoiceDetails";

function PoInvoiceDetails() {
  const location = useLocation();
  const previousState= location.state
  let { orderNumber }: any = useParams();
  return (
    <InvoiceDetails
      orderNumber={orderNumber}
      previousPath={previousState?.previousPath}
      extra={{
        hideAside: true,
        reduceTableWidth: 130,
        previousState
      }}
    />
  );
}

export default PoInvoiceDetails; 