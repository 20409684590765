import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import usersApis from "../../../api/master/users";
// take all apis by importing form the respective folder

interface Accessibility {
  accessID: number;
  module: number;
  add: boolean;
  view: boolean;
  edit: boolean;
  cancel: boolean;
  approve: boolean;
  reject: boolean;
  orgID: number;
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
  status: string;
  deletedAt: string | null;
  userID: number;
}

interface SiteAccessibility {
  id: number;
  siteID: number;
  orgID: string;
  userID: number;
  status: string;
  createdBy: string;
  modifiedBy: string;
  createdDate: string;
  modifiedDate: string;
  deletedAt: string | null;
  siteName: string;
}

interface Data {
  id: number;
  userId: number | null;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  orgid: number;
  orgType: string;
  orgRole: string;
  genericRole: string;
  roleId: number;
  userDetailsId: number;
  status: string;
  currentStatus: string;
  createdBy: number;
  modifiedBy: number | null;
  emailVerified: boolean;
  passwordChangeRequired: boolean;
  loginActivity: any[]; // Assuming the structure is unknown, otherwise replace `any[]` with the correct type.
  accessibility: Accessibility[];
  siteAccessibility: SiteAccessibility[];
  registeredAddress:any
  prefferedStates: any[]; // Assuming it's an array of any type, replace if you know the exact structure.
}

// Define an async thunk to fetch the current user
export const fetchCurrentUser = createAsyncThunk(
  'currentUser/fetchCurrentUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await usersApis.getCurrentUser();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

interface CurrentUserState {
  data: Data | null;
  loading: boolean;
  error: string | null;
}

const initialState: CurrentUserState = {
  data: null,
  loading: false,
  error: null,
};

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    logout(state) {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCurrentUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});
export const { logout } = currentUserSlice.actions;
export default currentUserSlice.reducer;