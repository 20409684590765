import makeApiCall from "./axiosIndex";
const dashboardApis = {
    // /gateWay/dashboard/seller/fetchSuppliersQuotationCount
  fetchSuppliersQuotationCount: (fromDate: any, toDate: any): Promise<any> =>   
    makeApiCall<any>({
      method: "get",
      url: `/dashboard/seller/fetchSuppliersQuotationCount?fromDate=${fromDate}&toDate=${toDate}`,
    }),
  //    /gateWay/dashboard/seller/fetchSuppliersOrderCount
  fetchSuppliersOrderCount: (fromDate: any, toDate: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/dashboard/seller/fetchSuppliersOrderCount?fromDate=${fromDate}&toDate=${toDate}`,
    }),
  // /gateWay/dashboard/seller/fetchSupplierMonthOnMonthTrend
  fetchSupplierMonthOnMonthTrend: (toDate: any): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/dashboard/seller/fetchSupplierMonthOnMonthTrend?date=${toDate}`,
    }),
  // /gateWay/dashboard/seller/fetchSupplierInvoiceAndPaymentSummary
  fetchSupplierInvoiceAndPaymentSummary: (
    fromDate: any,
    toDate: any
  ): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/dashboard/seller/fetchSupplierInvoiceAndPaymentSummary?fromDate=${fromDate}&toDate=${toDate}`,
    }),
  // /gateWay/dashboard/seller/fetchSupplierInvoiceToPaymentSummary
  fetchSupplierInvoiceToPaymentSummary: (
    fromDate: any,
    toDate: any
  ): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/dashboard/seller/fetchSupplierInvoiceToPaymentSummary?fromDate=${fromDate}&toDate=${toDate}`,
    }),
  // /gateWay/dashboard/seller/fetchSupplierPaymentOverdueSummary
  fetchSupplierPaymentOverdueSummary: (
    fromDate: any,
    toDate: any
  ): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/dashboard/seller/fetchSupplierPaymentOverdueSummary?fromDate=${fromDate}&toDate=${toDate}`,
    }),
  // /gateWay/dashboard/seller/fetchSupplierPaymentOutstandingSummary
  fetchSupplierPaymentOutstandingSummary: (
    fromDate: any,
    toDate: any
  ): Promise<any> =>
    makeApiCall<any>({
      method: "get",
      url: `/dashboard/seller/fetchSupplierPaymentOutstandingSummary?fromDate=${fromDate}&toDate=${toDate}`,
    }),
};

export default dashboardApis;
