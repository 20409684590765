import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useLocation, useNavigate, useParams } from "react-router";
import dayjs from "dayjs";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";
import FormatDate from "../../masters/DateFormater";


function InvoicesForSalesByMaterialReport() {
   const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const name = location.state?.name;  

  const columns: any = [
   {
      key: "orderId",
      title: "Order #",
      dataIndex: "orderId",
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/purchase-order-report-preview/${record.orderNumber}`, {
                state: {
                  previousPath: `/invoices-for-sales-by-material-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      width: 100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
   {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/invoices-for-sales-by-material-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      width: 100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    }, 
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      width:300,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity: string,record:any) =><span>{quantity} {record?.quantityUom}</span>
      
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Amount Without Tax",
      dataIndex: "amountWithoutTax",
      key: "amountWithoutTax",
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ]
  return (
    <DefaultReportTemplate
      pageTitle={`${name} - Invoices, Detail Report`}
      moduleKey="invoicesForSalesByMaterialReport"
      columns={columns}
      filtersOption={{
        customer: false,
        dateRange: false,
      }}
      previousPath="/sales-by-material-report"
      extra={{
        id:id,
        tableScrollX:1800
      }}
    />
  );
}

export default InvoicesForSalesByMaterialReport