import makeApiCall from "../master/axiosIndex";
import { LoginCredentials } from "../types/auth/authTypes";
const authService = {
  login: (credentials: LoginCredentials): Promise<any> => {
    return makeApiCall({
      url: "/auth/login",
      method: "post",
      data: credentials,
    });
  },
  setPassword: (credentials: any, accessToken: any): Promise<any> => {
    return makeApiCall({
      url: "/users/editPassword",
      method: "put",
      data: credentials,
    });
  },
  reSetPassword: (data: any): Promise<any> => {
    return makeApiCall({
      url: "/users/sendResetEmailToRegisteredUser",
      method: "post",
      data,
    });
  },
  logout: (): Promise<any> => {
    return makeApiCall({
      url: "/auth/logout",
      method: "post",
    });
  },
  refreshToken: (): Promise<any> => {
    return makeApiCall({
      url: "/auth/refresh",
      method: "post",
    });
  },
};

export default authService;
