import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import { formatCurrency } from "../../../utils/Utils";

function SalesByMaterialReport() {
  const navigate = useNavigate();

  const columns: any = [
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      fixed: "left",
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "PO Count",
      dataIndex: "pocount",
      key: "poCount",
      // render(text: any, record: any) {
      //   return (
      //     <span
      //       className="inlineTableCellLink"
      //       onClick={() => {
      //         navigate(`/order-details-by-category-report/${record?.materialId}`);
      //       }}
      //     >
      //       {" "}{text}{" "}
      //     </span>
      //   );
      // },
    },
    {
      title: "Invoice Count",
      dataIndex: "invoiceCount",
      key: "invoiceCount",
    },
    {
      title: "Unique Customers",
      dataIndex: "uniqueCustomers",
      key: "uniqueCustomers",
      // render(text: any, record: any) {
      //   return (
      //     <span
      //       className="inlineTableCellLink"
      //       onClick={() => {
      //         navigate(`/invoice-details-by-category-report/${record?.materialId}`);
      //       }}
      //     >
      //       {" "}{text}{" "}
      //     </span>
      //   );
      // },
    },
    {
      title: "Amount Without Tax",
      dataIndex: "amountWithoutTax",
      key: "amountWithoutTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(
                `/invoices-for-sales-by-material-report/${record?.materialId}`,
                {
                  state: {
                    name: record?.materialName,
                  },
                }
              );
            }}
          >
            {" "}
            {formatCurrency(text?.toString(),record?.currencyCode)}{" "}
          </span>
        </div>
      ),
    },
    {
      title: "Amount With Tax",
      dataIndex: "amountWithTax",
      key: "amountWithTax",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(
                `/invoices-for-sales-by-material-report/${record?.materialId}`,
                {
                  state: {
                    name: record?.materialName,
                  },
                }
              );
            }}
          >
            {" "}
            {formatCurrency(text?.toString(),record?.currencyCode)}{" "}
          </span>
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Sales By Material"
      moduleKey="salesByMaterialReport"
      columns={columns}
      filtersOption={{
        customer: true,
        dateRange: true,
      }}
      previousPath="/view-all-sales-reports"
      extra={{
        tableScrollX: 1000,
      }}
    />
  );
}

export default SalesByMaterialReport