import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

const statusMapper: any = {
    "To be Invoiced": {
    buyer: {
      title: "To be Invoiced",
      color: "purple",
      icon: <ExclamationCircleOutlined />,
    },
    seller: {
      title: "To be Invoiced",
      color: "purple",
      icon: <ExclamationCircleOutlined />,
    },
  },
  "To be Dispatched": {
    buyer: {
      title: "To be Dispatched",
      color: "blue",
      icon: <SyncOutlined />,
    },
    seller: {
      title: "To be Dispatched",
      color: "blue",
      icon: <SyncOutlined />,
    },
  },
  "To be Delivered": {
    buyer: {
      title: "To be Delivered",
      color: "blue",
      icon: <SyncOutlined />,
    },
    seller: {
      title: "To be Delivered",
      color: "blue",
      icon: <SyncOutlined />,
    },
  },
  "Partially Dispatched": {
    buyer: {
      title: "Partially Dispatched",
      color: "orange",
      icon: <ExclamationCircleOutlined />,
    },
    seller: {
      title: "Partially Dispatched",
      color: "orange",
      icon: <ExclamationCircleOutlined />,
    },
  },
  "Partially Delivered": {
    buyer: {
      title: "Partially Delivered",
      color: "orange",
      icon: <ExclamationCircleOutlined />,
    },
    seller: {
      title: "Partially Delivered",
      color: "orange",
      icon: <ExclamationCircleOutlined />,
    },
  },
  "Fully Dispatched": {
    buyer: {
      title: "Fully Dispatched",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
    seller: {
      title: "Fully Dispatched",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
  },
  "Fully Delivered": {
    buyer: {
      title: "Fully Delivered",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
    seller: {
      title: "Fully Delivered",
      color: "green",
      icon: <CheckCircleOutlined />,
    },
  },

};

export default statusMapper;
