import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Badge, Button, Card, Col, Row } from "reactstrap";

import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import InvoiceComponent from "./InvoiceComponent";
import invoiceApis from "../../api/Invoice/invoice";
import DispatchComponent from "./dispatchComponent";
import dispatchApis from "../../api/dispatch/dispatch";
import { set } from "react-hook-form";
function ViewMaterialDispatch() {
  const { invoiceNumber, invoiceLineNo} = useParams();
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [dispatchNumbers, setInvoiceNumbers] = useState<any>([]);
  const [activeDispatch, setActiveDispatch] = useState<any>({});
// {
//             "dispatchNumber": 5,
//             "dispatchId": "CUS37-DISP-000005"
//         }
  const materialInvoice = async () => {
    try {
      const response = await dispatchApis.fetchAllDispatchesByInvoiceLineNo(invoiceLineNo)
      const { data, status } = response;
      if (status && data) {
        setInvoiceNumbers(data);
        setActiveDispatch(data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    materialInvoice();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const isActive = (dispatch: any): string => {
    return activeDispatch?.dispatchId== dispatch? "active" : "";
  };
  return (
    <>
      <Head title="Material Invoice" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg 
                ${sm ? "content-active" : ""}
              `}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{ fontSize: 14 }}>
                      Dispatch
                    </h6>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                    {dispatchNumbers.map((dispatch: any) => {
                      return (
                        <li
                          onClick={() => {
                            setActiveDispatch(dispatch);
                          }}
                        >
                          <Link to="" className={isActive(dispatch?.dispatchId)}>
                            <span>{dispatch?.dispatchId}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {activeDispatch && (
              <DispatchComponent
                dispatchNumber={activeDispatch?.dispatchNumber}
                previousPath={"/post-dispatch-invoice/" + invoiceNumber}
              />
            )}
          </div>
        </Card>
      </Content>
    </>
  );
}

export default ViewMaterialDispatch;
