import React, { useState, useEffect, useMemo, FC, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Badge, Card } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  RSelect,
  UserAvatar,
  TooltipComponent,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import axios from "../../../axios";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Modal, ModalBody } from "reactstrap";
import DatePicker from "react-datepicker";
import { getDateStructured } from "../../../utils/Utils";
import supplierApis from "../../../api/master/supplier";
import { useNavigate } from "react-router-dom";
import FormatDate from "../DateFormater";
import usersApis from "../../../api/master/users";
import { FormProvider, useForm, useFormContext } from "react-hook-form";

interface SelectOptions {
  label: string;
  value: string;
}
const ViewSuppliersDetails: FC = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  let { siteId } = useParams();
  const [data, setData]: any = useState();
  const [modalGI, setGIModal] = useState(false);
  const [modalTab, setModalTab] = useState("1");
  const [submitEditData, setSubmitEditData] = useState(false);
  const [modalEditBlockName, setModalEditBlockName]: any = useState("");

  const navigate = useNavigate();

  const [countries, setCountries] = useState<SelectOptions[]>([]);
  const [states, setStates] = useState<SelectOptions[]>([]);
  const [districtOptions, setDistrictOptions] = useState<SelectOptions[]>([]);
  const [cities, setCities] = useState([]);
  const methods = useForm();

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  //   setValue,
  //   // watch,
  //   reset,
  // } = methods;

  // const onmaterialChangeWatch = watch("materials");

  const [formData, setFormData] = useState<any>({
    referenceID: "",
    supplierName: "",
    // industryType: "",
    supplierCategory: "",
    incorporationDate: "",
    pan: "",
    gstin: "",
    lastFYTurnover: "",
    contactPerson: "",
    phoneNumber: "",
    email: "",
    country: "",
    state: "",
    city: "",
    area: "",
    pincode: "",
    accountNumber: "",
    bankName: "",
    branch: "",
    ifscCode: "",
    minSupply: "",
    deliveryLeadDays: "",
    paymentTerms: "",
    // supplyStates: "",
    accountHolderName: "",
    supplyStates: [],
    cin: "",
    categories: [],
    materials: [],
  });

  const submitForm = () => {
    let submitData = {
      ...formData,
    };
    // setData(submitData);
    // setSubmitEditData(true)
    submitUpdates(submitData);
    setGIModal(false);
  };

  const onInputChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [categories, setCategories] = useState<SelectOptions[]>([]);
  const [industryTypeOptions, setIndustryTypeOptions] = useState<any[]>([]);
  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  const handleMultiSelectChange = (selectedOptions: any, name: any) => {
    console.log("selectedOptions", selectedOptions);
    if (name == "categories") {
      const value = selectedOptions.map((option: any) => ({
        categoryID: option.value,
        categoryName: option.label,
      }));
      setFormData({ ...formData, categories: value });
      console.log(value, "value111");
      // setFormData({ name:value });
    } else if (name == "materials") {
      const value = selectedOptions.map((option: any) => ({
        materialID: option.value,
        materialName: option.label,
      }));
      setFormData({ ...formData, materials: value });
      console.log(value, "value2222");
    } else if (name == "supplyStates") {
      const value = selectedOptions.map((option: any) => ({
        stateID: option.value,
        stateName: option.label,
      }));
      setFormData({ ...formData, supplyStates: value });
    }
  };
  const fetchSupplierCategory = async () => {
    try {
      const { data, status, message } =
        await supplierApis.fetchSupplierCategory();
      if (status) {
        console.log(data);
        setCategories(
          data.map((obj: any) => ({ label: obj.category, value: obj.id }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  const fetchIndustryType = async () => {
    try {
      const { data, status, message } =
        await supplierApis.getContructionsMaterialTypes();
      if (status) {
        console.log(data);
        setIndustryTypeOptions(
          data.map((obj: any) => ({ label: obj.name, value: obj.material_id }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };

  const getCountries = async () => {
    try {
      const _countries = await usersApis.getCountrys();
      if (_countries.status) {
        if (_countries.data.length > 0) {
          const transformedData = _countries.data.map((item: any) => ({
            value: item.countryId.toString(),
            label: item.countryName,
          }));
          setCountries(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const fetchStatesByCountry = async (countryId: string, stateId?: string) => {
    try {
      const data = await usersApis.getStatesByCountry(countryId);
      const stateOptions = data?.data?.map((state: any) => ({
        value: state.stateId.toString(),
        label: state.stateName,
      }));
      if (stateOptions.length > 0) setStates(stateOptions);
      if (stateId) {
        const state = stateOptions.find(
          (state: SelectOptions) => state.value === stateId
        );
        if (!state) {
          return;
        }
        // setValue("state", state.value);
      }
    } catch (error) {}
  };
  const getDistricts = async (stateID: any) => {
    try {
      const districts = await usersApis.getDistrictsByState(stateID);
      console.log(districts);
      if (districts.status) {
        if (districts.data.length > 0) {
          const transformedData = districts.data.map((item: any) => ({
            value: item.districtId.toString(),
            label: item.districtName,
          }));
          setDistrictOptions(transformedData);
        }
      }
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    viewChange();

    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    const id = siteId;
    if (id !== undefined || null || "") {
      fetchSupplierData(id);
      fetchSupplierCategory();
      fetchIndustryType();
      getCountries();
    }
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, [siteId]);

  // useEffect(() => {
  //   fetchSupplierCategory();
  //   fetchIndustryType();
  //   getCountries();
  // }, []);

  const openEditModal = (modalEditBlockName: any) => {
    // fetchSupplierCategory();
    // fetchIndustryType();
    // getCountries();
    setModalEditBlockName(modalEditBlockName);
    setGIModal(true);
  };

  const submitUpdates = async (submitData: any) => {
    submitData.country = submitData.country.countryId;
    submitData.state = submitData.state.stateId;
    console.log("updatedDATA::::", submitData);
    const { status, message } = await supplierApis.updateSupplierById(
      siteId,
      submitData
    );
    if (status) {
      Swal.fire("Updated!", "Supplier has been updated", "success");
      const id = siteId;
      console.log("id", id);
      if (id !== undefined || null || "") {
        fetchSupplierData(id);
      }
      // navigate(`/suppliers`);
    } else {
      Swal.fire("Failed!", message, "error");
    }
  };

  const fetchSupplierData = async (id: any) => {
    try {
      const { data, status, message } = await supplierApis.fetchSupplierById(
        id
      );
      console.log("status", data);
      if (status) {
        console.log("status", data);
        const newData = data;
        setData(newData); // Update data with fetched response
        setFormData({
          referenceID: newData.referenceID,
          supplierName: newData.supplierName,
          // industryType: newData.industryType,
          supplierCategory: newData.supplierCategory,
          incorporationDate: newData.incorporationDate,
          pan: newData.pan,
          gstin: newData.gstin,
          lastFYTurnover: newData.lastFYTurnover,
          contactPerson: newData.contactPerson,
          phoneNumber: newData.phoneNumber,
          email: newData.email,
          country: newData.country,
          state: newData.state,
          city: newData.city,
          area: newData.area,
          pincode: newData.pincode,
          accountNumber: newData.accountNumber,
          bankName: newData.bankName,
          branch: newData.branch,
          ifscCode: newData.ifscCode,
          minSupply: newData.minSupply,
          deliveryLeadDays: newData.deliveryLeadDays,
          paymentTerms: newData.paymentTerms,
          // supplyStates: newData.supplyStates,
          accountHolderName: newData.accountHolderName,
          supplyStates: newData.supplyStates,
          cin: newData.cin,
          categories: newData.categories,
          materials: newData.materials,
        });
        fetchIndustryType();
        fetchStatesByCountry(newData.country, newData.state);
        getDistricts(newData.state);
      }
    } catch (err: any) {
      Swal.fire(
        "Failed!",
        err.response?.data?.message || "Unknown error",
        "error"
      );
    }
  };

  return (
    <React.Fragment>
      <Head title="Site Details - Regular"></Head>
      <Content>
        {data && (
          <>
            <BlockHead size="sm">
              <BlockBetween className="g-3">
                <BlockHeadContent>
                  <BlockTitle page>
                    Supplier Details -{" "}
                    <strong className="text-primary small">
                      {data["supplierName"]}
                    </strong>
                  </BlockTitle>
                  <BlockDes className="text-soft">
                    <ul className="list-inline">
                      {/* <li>
                                                Site ID: <span className="text-base">{data.siteID}</span>
                                            </li> */}
                      <li>
                        Created At:{" "}
                        <span className="text-base">
                          {FormatDate(data.createdDate)}
                        </span>
                      </li>
                    </ul>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  {/* {
                                        submitEditData &&
                                        <Button outline className="bg-white d-none d-sm-inline-flex" style={{ marginRight: "10px" }} onClick={() => submitUpdates()}>
                                            <Icon name="check-round-cut"></Icon>
                                            <span>Submit Updates</span>
                                        </Button>
                                    } */}

                  {/* <Button outline className="bg-white d-none d-sm-inline-flex" style={{ marginRight: "10px" }} onClick={() => openEditModal()}>
                                        <Icon name="edit-alt-fill"></Icon>
                                        <span>Edit</span>
                                    </Button> */}
                  <Link to={`${process.env.PUBLIC_URL}/suppliers`}>
                    <Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                    >
                      <Icon name="arrow-left"></Icon>
                      <span>Back</span>
                    </Button>
                    <Button
                      outline
                      className="btn-icon bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                  </Link>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Block>
              <Row className="gy-5">
                <Col
                  lg="5"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">General Information</BlockTitle>
                        <p>Name, Address etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("GeneralInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Internal ID</div>
                          <div className="data-value">{data.referenceID}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Supplier Name</div>
                          <div className="data-value">{data.supplierName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Material Type</div>
                          <div className="data-value">
                            {formData.materials
                              .map((material: any) => material.materialName)
                              .join(", ")}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Supply Categories</div>
                          <div className="data-value">
                            {formData.categories
                              .map((categories: any) => categories.categoryName)
                              .join(", ")}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Legal Information</BlockTitle>
                        <p>PAN, GSTIN etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("LegalInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>
                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Incorporation Date</div>
                          <div className="data-value">
                            {FormatDate(data.incorporationDate)}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">PAN</div>
                          <div className="data-value">{data.pan}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">GSTIN</div>
                          <div className="data-value">{data.gstin}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Last FY Turn over</div>
                          <div className="data-value">
                            {data.lastFYTurnover}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>

                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Other Information</BlockTitle>
                        <p>Some more Other details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("OtherInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Min.Supply</div>
                          <div className="data-value">{data.minSupply}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Delivery Lead Days</div>
                          <div className="data-value">
                            {data.deliveryLeadDays}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Payment Terms</div>
                          <div className="data-value">{data.paymentTerms}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Supply States</div>
                          <div className="data-value">
                            {formData.supplyStates
                              .map(
                                (supplyStates: any) => supplyStates.stateName
                              )
                              .join(", ")}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>

                <Col
                  lg="7"
                  sm={undefined}
                  md={undefined}
                  xxl={undefined}
                  size={undefined}
                  className={undefined}
                >
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Contact Information</BlockTitle>
                        <p>Basic Contact Information Details etc.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("ContactInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Contact Person</div>
                          <div className="data-value">{data.contactPerson}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Phone Number</div>
                          <div className="data-value">{data.phoneNumber}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Email</div>
                          <div className="data-value">{data.email}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Country</div>
                          <div className="data-value">
                            {
                              countries.find((c) => c.value == data.country)
                                ?.label
                            }
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">State</div>
                          <div className="data-value">
                            {states.find((s) => s.value == data.state)?.label}
                          </div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">City</div>
                          <div className="data-value">{data.city}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Area</div>
                          <div className="data-value">{data.area}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Zip/Pincode</div>
                          <div className="data-value">{data.pincode}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                  <BlockHead>
                    <BlockBetween>
                      <BlockHeadContent>
                        <BlockTitle tag="h5">Bank Information</BlockTitle>
                        <p>Here are the bank details.</p>
                      </BlockHeadContent>
                      <BlockHeadContent>
                        <Button
                          outline
                          className="d-none d-sm-inline-flex"
                          style={{ marginRight: "10px" }}
                          onClick={() => openEditModal("BankInformation")}
                        >
                          <Icon name="edit-alt-fill"></Icon>
                        </Button>
                      </BlockHeadContent>
                    </BlockBetween>
                  </BlockHead>

                  <Card className="card-bordered">
                    <ul className="data-list is-compact">
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Account Number</div>
                          <div className="data-value">{data.accountNumber}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Bank Name</div>
                          <div className="data-value">{data.bankName}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Branch</div>
                          <div className="data-value">{data.branch}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">IFSC Code</div>
                          <div className="data-value">{data.ifscCode}</div>
                        </div>
                      </li>
                      <li className="data-item">
                        <div className="data-col">
                          <div className="data-label">Cancelled Cheque</div>
                          <div className="data-value">{data.files}</div>
                        </div>
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Block>

            <Modal
              isOpen={modalGI}
              className="modal-dialog-centered"
              size="xl"
              toggle={() => setGIModal(false)}
              backdrop="static"
            >
              <a
                href="#dropdownitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  setGIModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>

              {modalEditBlockName === "GeneralInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit General Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      {/* <FormProvider {...methods}> */}
                      {/* <form className="content clearfix" > */}
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="referenceID">
                              Internal ID
                            </label>
                            <input
                              type="text"
                              id="referenceID"
                              className="form-control"
                              name="referenceID"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.referenceID}
                              placeholder="Enter Internal ID"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="supplierName"
                            >
                              Supplier Name
                            </label>
                            <input
                              type="text"
                              id="supplierName"
                              className="form-control"
                              name="supplierName"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.supplierName}
                              placeholder="Enter Supplier Name"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="materialType"
                            >
                              Material Type
                            </label>
                            <RSelect
                              isMulti
                              name="materials"
                              options={industryTypeOptions}
                              placeholder="Select Industry Type"
                              defaultValue={formData.materials.map(
                                (material: any) => ({
                                  label: material.materialName,
                                  value: material.materialID,
                                })
                              )}
                              onChange={(value: any) =>
                                handleMultiSelectChange(value, "materials")
                              }
                              closeMenuOnSelect={false}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <label
                            className="form-label"
                            htmlFor="supplierCategory"
                          >
                            Supply Categories
                          </label>
                          <RSelect
                            isMulti
                            options={categories}
                            closeMenuOnSelect={false}
                            name="categories"
                            placeholder="Select a Supply Categories"
                            defaultValue={formData.categories.map(
                              (categories: any) => ({
                                label: categories.categoryName,
                                value: categories.categoryID,
                              })
                            )}
                            onChange={(value: any) =>
                              handleMultiSelectChange(value, "categories")
                            }
                          />
                        </Col>
                        <Col size="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                      {/* </form> */}
                      {/* </FormProvider> */}
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "LegalInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Legal Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="incorporationDate"
                            >
                              Incorporation Date
                            </label>
                            <DatePicker
                              disabled
                              selected={new Date(formData.incorporationDate)}
                              className="form-control"
                              onChange={(date) =>
                                setFormData({
                                  ...formData,
                                  incorporationDate: getDateStructured(date),
                                })
                              }
                              maxDate={new Date()}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="pan">
                              pan
                            </label>
                            <input
                              type="text"
                              disabled
                              id="pan"
                              className="form-control"
                              name="pan"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.pan}
                              placeholder="Enter pan"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="gstin">
                              GSTIN
                            </label>
                            <input
                              type="text"
                              disabled
                              id="gstin"
                              className="form-control"
                              name="gstin"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.gstin}
                              placeholder="Enter GSTIN"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="lastFYTurnover"
                            >
                              Last FY Turn over
                            </label>
                            <input
                              type="number"
                              id="lastFYTurnover"
                              className="form-control"
                              name="lastFYTurnover"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.lastFYTurnover}
                              placeholder="Enter Last FY Turn over"
                            />
                          </div>
                        </Col>
                        <Col size="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "ContactInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Contact Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="4">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="contactPerson"
                            >
                              Contact Person
                            </label>
                            <input
                              type="text"
                              // disabled
                              id="contactPerson"
                              className="form-control"
                              name="contactPerson"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.contactPerson}
                              placeholder="Enter Contact Person"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="phoneNumber">
                              Phone Number
                            </label>
                            <input
                              type="text"
                              pattern="^\d+$"
                              // disabled
                              id="phoneNumber"
                              className="form-control"
                              name="phoneNumber"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.phoneNumber}
                              placeholder="Enter Phone Number"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="email">
                              Email
                            </label>
                            <input
                              type="text"
                              id="email"
                              className="form-control"
                              name="email"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.email}
                              placeholder="Enter Email"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="country">
                              Country
                            </label>
                            <RSelect
                              options={countries}
                              value={countries.find(
                                (option) => option.value === formData.country
                              )}
                              onChange={(value: any) => {
                                setFormData({
                                  ...formData,
                                  country: value,
                                });
                                fetchStatesByCountry(value);
                              }}
                            />
                            {/* <input
                              type="text"
                              id="country"
                              className="form-control"
                              name="country"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.country}
                              placeholder="Enter Country"
                            /> */}
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="state">
                              State
                            </label>
                            <RSelect
                              options={states}
                              value={states.find(
                                (option) => option.value === formData.state
                              )}
                              onChange={(value: any) =>
                                setFormData({
                                  ...formData,
                                  state: value,
                                })
                              }
                            />
                            {/* <input
                              type="text"
                              id="state"
                              className="form-control"
                              name="state"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.state}
                              placeholder="Enter State"
                            /> */}
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="city">
                              City
                            </label>
                            <input
                              type="text"
                              id="city"
                              className="form-control"
                              name="city"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.city}
                              placeholder="Enter City"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="area">
                              Area
                            </label>
                            <input
                              type="text"
                              id="area"
                              className="form-control"
                              name="area"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.area}
                              placeholder="Enter Area"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="pincode">
                              Zip/Pincode
                            </label>
                            <input
                              type="number"
                              id="pincode"
                              className="form-control"
                              name="pincode"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.pincode}
                              placeholder="Enter Zip/Pincode"
                            />
                          </div>
                        </Col>
                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "BankInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Bank Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="4">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="accountNumber"
                            >
                              Account Number
                            </label>
                            <input
                              type="text"
                              disabled
                              id="accountNumber"
                              className="form-control"
                              name="accountNumber"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.accountNumber}
                              placeholder="Enter Account Number"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="bankName">
                              Bank Name
                            </label>
                            <input
                              type="number"
                              disabled
                              id="bankName"
                              className="form-control"
                              name="bankName"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.bankName}
                              placeholder="Enter Bank Name"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="branch">
                              Branch
                            </label>
                            <input
                              type="text"
                              disabled
                              id="branch"
                              className="form-control"
                              name="branch"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.branch}
                              placeholder="Enter Branch"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label className="form-label" htmlFor="ifscCode">
                              IFSC Code
                            </label>
                            <input
                              type="text"
                              id="ifscCode"
                              disabled
                              className="form-control"
                              name="ifscCode"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.ifscCode}
                              placeholder="Enter IFSC Code"
                            />
                          </div>
                        </Col>
                        <Col md="4">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="cancelledCheque"
                            >
                              Cancelled Cheque
                            </label>
                            <input
                              type="text"
                              disabled
                              id="cancelledCheque"
                              className="form-control"
                              name="cancelledCheque"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.cancelledCheque}
                              placeholder="Enter Cancelled Cheque"
                            />
                          </div>
                        </Col>

                        <Col md="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}

              {modalEditBlockName === "OtherInformation" && (
                <ModalBody>
                  <div className="">
                    <div className="nk-modal-head">
                      <h5 className="title">Edit Other Information </h5>
                    </div>
                    <div className="nk-tnx-details mt-sm-3">
                      <Row className="gy-4">
                        <Col md="6">
                          <div className="form-group">
                            <label className="form-label" htmlFor="minSupply">
                              Min.Supply
                            </label>
                            <input
                              type="number"
                              id="minSupply"
                              className="form-control"
                              name="minSupply"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.minSupply}
                              placeholder="Enter Min.Supply"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="deliveryLeadDays"
                            >
                              Delivery Lead Days
                            </label>
                            <input
                              type="number"
                              id="deliveryLeadDays"
                              className="form-control"
                              name="deliveryLeadDays"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.deliveryLeadDays}
                              placeholder="Enter Delivery Lead Days"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="paymentTerms"
                            >
                              Payment Terms
                            </label>
                            <input
                              type="text"
                              id="paymentTerms"
                              className="form-control"
                              name="paymentTerms"
                              onChange={(e) => onInputChange(e)}
                              defaultValue={formData.paymentTerms}
                              placeholder="Enter Payment Terms"
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          <label className="form-label" htmlFor="supplyStates">
                            Supply State
                          </label>
                          <RSelect
                            isMulti
                            closeMenuOnSelect={false}
                            options={states}
                            placeholder="Select a Supply State"
                            // value={states.find(
                            //   (option) => option.value === formData.supplyStates
                            // )}
                            defaultValue={formData.supplyStates.map(
                              (supplyStates: any) => ({
                                label: supplyStates.stateName,
                                value: supplyStates.stateID,
                              })
                            )}
                            onChange={(value: any) =>
                              handleMultiSelectChange(value, "supplyStates")
                            }
                            // defaultValue={[
                            //   {
                            //     value: formData.supplyStates,
                            //     label: formData.supplyStates,
                            //   },
                            // ]}
                            // onChange={(e: any) =>
                            //   setFormData({
                            //     ...formData,
                            //     supplyStates: e.value,
                            //   })
                            // }
                          />
                        </Col>
                        <Col size="12">
                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                            <li>
                              <Button
                                color="primary"
                                size="lg"
                                onClick={() => submitForm()}
                              >
                                Update
                              </Button>
                            </li>
                            <li>
                              <a
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setGIModal(false);
                                }}
                                className="link link-light"
                              >
                                Cancel
                              </a>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </ModalBody>
              )}
            </Modal>
          </>
        )}
      </Content>
    </React.Fragment>
  );
};

export default ViewSuppliersDetails;
