import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../../layout/head/Head";
import { Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../../components/Component";
import { Row, Col } from "reactstrap";
import OrgProfileAside from "./OrgProfileAside";
import FormInput from "../../form-components/InputComponent";
import orgsApis from "../../../api/master/org";
import FormDatePicker from "../../form-components/DatePicker";
import { FormProvider, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import FormatDate from "../DateFormater";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

const OrgLegalInformation = () => {
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [data, setData] = useState<any>();

  const methods = useForm<any>({
    mode: "onChange",
    defaultValues: {
      cin: "",
      incorporationDate: "",
      pan: "",
      gstin: "",
    },
  });

  const { handleSubmit, setValue } = methods;
  const [modal, setModal] = useState(false);

  const openEditModal = () => {
    setModal(true);
  };

  const onSubmit = async (submitedFormData: any) => {
    const submitedData: any = new FormData();
    submitedData.append("files", JSON.stringify([]));
    submitedData.append(
      "filesData",
      JSON.stringify({
        newfiledata: [],
        deletedFileData: [],
        updatedFileData: [],
      })
    );
    submitedData.append("orgData", JSON.stringify(submitedFormData));
    try {
      const { status, message } = await orgsApis.updateOrgById(
        data.orgID,
        submitedData
      );
      if (status) {
        Swal.fire(
          "Updated!",
          "Organisation Details has been updated",
          "success"
        );
        setModal(false);
        fetchOrgData();
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error: any) {
      Swal.fire("Failed!", error.response.data.message, "error");
    }
  };

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  const fetchOrgData = async () => {
    try {
      const { data, status, message } = await orgsApis.fetchOrg();
      console.log("status", data);
      if (status) {
        const newData = data;
        setData(newData);
        setValue("cin", newData.cin);
        setValue("incorporationDate", dayjs(newData.incorporationDate));
        setValue("pan", newData.pan);
        setValue("gstin", newData.gstin);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    fetchOrgData();
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <OrgProfileAside updateSm={updateSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => updateSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">Legal Information</BlockTitle>
                    <BlockDes>
                      <p>Basic Legal Information...</p>
                    </BlockDes>
                  </BlockHeadContent>
                  <BlockHeadContent>
                    {allowedActions?.edit?<Button
                      outline
                      className="bg-white d-none d-sm-inline-flex"
                      style={{ marginRight: "10px" }}
                      onClick={() => openEditModal()}
                    >
                      <Icon name="edit-alt-fill"></Icon>
                      <span>Edit</span>
                    </Button>:<></>}
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => updateSm(!sm)}
                    >
                      <Icon name="menu-alt-r"></Icon>
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                {data && (
                  <div className="nk-data data-list">
                    <div className="data-head">
                      <h6 className="overline-title">Basics</h6>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">CIN</span>
                        <span className="data-value">{data.cin}</span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">Incorporation Date</span>
                        <span className="data-value">
                          {FormatDate(data.incorporationDate)}
                        </span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">PAN</span>
                        <span className="data-value">{data.pan}</span>
                      </div>
                    </div>
                    <div className="data-item">
                      <div className="data-col">
                        <span className="data-label">GSTIN</span>
                        <span className="data-value">{data.gstin}</span>
                      </div>
                    </div>
                  </div>
                )}
              </Block>

              <Modal
                isOpen={modal}
                className="modal-dialog-centered"
                size="lg"
                toggle={() => setModal(false)}
              >
                <a
                  href="#dropdownitem"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModal(false);
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
                <ModalBody>
                  <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="p-2">
                        <h5 className="title">Edit Legal Details</h5>
                        <div className="tab-content">
                          <div className="p-2">
                            <Row className="gy-4">
                              <Col md="6">
                                <div className="form-group">
                                  <FormInput
                                    name="cin"
                                    label="CIN"
                                    type="text"
                                    required={true}
                                    disabled={data?.cin ? true : false}
                                    minLength={2}
                                    maxLength={30}
                                    // defaultValue={data?.cin}
                                    placeholder="Enter CIN"
                                    pattern={
                                      /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/
                                    }
                                  />
                                </div>
                              </Col>
                              <Col md="6">
                                <div className="form-group">
                                  <FormDatePicker
                                    name="incorporationDate"
                                    label="Incorporation Date"
                                    // disabled={
                                    //   data?.incorporationDate ? true : false
                                    // }
                                    //   defaultValue={data?.incorporationDate}
                                    // minDate={new Date()} // Today's date as the minimum date
                                    maxDate={new Date()} // Maximum date set to one year from today
                                    // Maximum date set to one year from today
                                    // defaultValue={
                                    //     data?.incorporationDate &&
                                    //     new Date(data?.incorporationDate)
                                    // }
                                  />
                                </div>
                              </Col>

                              <Col md="6">
                                <div className="form-group">
                                  <FormInput
                                    name="pan"
                                    label="PAN"
                                    required={true}
                                    disabled={data?.pan ? true : false}
                                    // defaultValue={data?.pan}
                                    type="text"
                                    placeholder="Enter PAN"
                                    pattern={/^[A-Z]{5}[0-9]{4}[A-Z]$/}
                                  />
                                </div>
                              </Col>

                              <Col md="6">
                                <div className="form-group">
                                  <FormInput
                                    name="gstin"
                                    label="GSTIN"
                                    // defaultValue={data?.gstin}
                                    required={true}
                                    disabled={data?.gstin ? true : false}
                                    type="text"
                                    placeholder="Enter GSTIN"
                                    pattern={
                                      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
                                    }
                                  />
                                </div>
                              </Col>

                              <Col size="12">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                  <li>
                                    <Button color="primary" size="lg">
                                      Update
                                    </Button>
                                  </li>
                                  <li>
                                    <a
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setModal(false);
                                      }}
                                      className="link link-light"
                                    >
                                      Cancel
                                    </a>
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </form>
                  </FormProvider>
                </ModalBody>
              </Modal>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default OrgLegalInformation;
