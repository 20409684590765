import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import dayjs from "dayjs";
import FormatDate from "../../masters/DateFormater";
import { TooltipComponent } from "../../../components/Component";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";
import { useLocation, useNavigate, useParams } from "react-router";


function PaymentsAgingSummaryInvoiceReport() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const name = location.state?.name; 
  const columns: any = [
    // {
    //   key: "orderId",
    //   title: "Order #",
    //   dataIndex: "orderId",
    //   fixed: "left",
    //   render(text: any, record: any) {
    //     return (
    //       <span
    //         className="inlineTableCellLink"
    //         onClick={() => {
    //           navigate(`/purchase-order-report-preview/${record.orderNumber}`, {
    //             state: {
    //               previousPath: `/payments-aging-summary-invoice-report/${id}`,
    //               name,
    //             },
    //           });
    //         }}
    //       >
    //         {text}
    //       </span>
    //     );
    //   },
    // }, 
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: {
                  previousPath: `/payments-aging-summary-invoice-report/${id}`,
                  name,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      width: 100,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    // {
    //   title: "Customer Name",
    //   dataIndex: "customerName",
    //   key: "customerName",
    //   fixed: "left",
    //   width: 250,
    //   render: (text: string, record: any, index: any) => {
    //     return (
    //       <>
    //         <span id={`customer-tooltip-${index}`}>
    //           {text?.length > 20 ? `${text!.substring(0, 25)}... ` : text}
    //         </span>
    //         {text?.length > 20 && (
    //           <TooltipComponent
    //             iconClass="info-circle text-info"
    //             icon="info"
    //             id={`customer-tooltip-${index}`}
    //             direction="top"
    //             text={text}
    //             containerClassName={""}
    //           />
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      title: "GRN Date",
      dataIndex: "grnDate",
      width: 100,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Due Date",
      dataIndex: "paymentDueDate",
      width: 100,
      render: (date: any) => <span>{date ? FormatDate(date) : "N/A"}</span>,
    },
    {
      title: "Aging",
      dataIndex: "aging",
      width: 100,
      render: (aging: any) => <span>{aging} days</span>,
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Outstanding Amount",
      dataIndex: "outstandingAmount",
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Overdue Amount",
      dataIndex: "overdueAmount",
      render: (text: any, record: any) => (
       <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle={`${name} -Receivables Aging Summary`}
      moduleKey="receivablesAgingSummaryDetailReport"
      columns={columns}
      filtersOption={{
        customer: false,
        dateRange: false,
        aging: false,
      }}
      previousPath="/payment-aging-summary-reports-list"
      extra={{
        tableScrollX: 1100,
        id
      }}
    />
  );
}

export default PaymentsAgingSummaryInvoiceReport;
