import React from "react";
import { useLocation, useParams } from "react-router";
import ViewOrderDetails from "../../purchase-orders/ViewOrderDetails";

function PurchaseOrderPreview() {
  const location = useLocation();
  const previousState= location.state
  let { orderNumber }: any = useParams();
  return (
    <ViewOrderDetails
      orderNumber={orderNumber}
      previousPath={previousState?.previousPath}
      extra={{
        hideAside: true,
        reduceTableWidth: 130,
        previousState
      }}
    />
  );
}

export default PurchaseOrderPreview;
