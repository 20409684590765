import React from "react";
import { useLocation, useParams } from "react-router";
import InvoiceComponent from "../../invoice/InvoiceComponent";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Card } from "reactstrap";

function InvoicePreviewReport() {
  const location = useLocation();
  const previousState= location.state
  let { id }: any = useParams();
  return (
    <>
      <Head title="Material Invoice" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <InvoiceComponent
              invoiceNumber={id}
              previousPath={previousState?.previousPath}
              extra={{
                reduceTableWidth: 130,
                previousState
              }}
            />
          </div>
        </Card>
      </Content>
    </>
  );
}

export default InvoicePreviewReport;
