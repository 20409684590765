// import React, { useState, useEffect, FC } from "react";
// import { Link, useLocation } from "react-router-dom";
// import { Icon, UserAvatar } from "../../components/Component"; // Assuming these are properly typed elsewhere
// import {
//   DropdownItem,
//   UncontrolledDropdown,
//   DropdownMenu,
//   DropdownToggle,
// } from "reactstrap";

// interface PurchaseRequestAsideProps {
//   updateSm: (value: boolean) => void; // Function to update state
//   sm: boolean; // Boolean state
// }

// const QuotationsAside: FC<PurchaseRequestAsideProps> = ({ updateSm, sm }) => {
//   const [profileName, setProfileName] = useState<string>("Abu Bin Ishtiak");
//   const location = useLocation();

//   useEffect(() => {
//     sm
//       ? document.body.classList.add("toggle-shown")
//       : document.body.classList.remove("toggle-shown");
//   }, [sm]);

//   const isActive = (path: string): string => {
//     return location.pathname === path ? "active" : "";
//   };

//   return (
//     <div className="card-inner-group">
//       <div className="card-inner">
//         <div className="user-account-info py-0">
//           <h6 className="overline-title-alt" style={{ fontSize: 14 }}>
//             Quotations
//           </h6>
//         </div>
//       </div>

//       <div className="card-inner p-0">
//         <ul className="link-list-menu">
//         <li onClick={() => updateSm(false)}>
//             <Link
//               to="/view-rfqs"
//               className={isActive(`${process.env.PUBLIC_URL}/view-rfqs`)}
//             >
//               <Icon name="eye-fill" />
//               <span>New Leads</span>
//             </Link>
//           </li>
//           <li onClick={() => updateSm(false)}>
//             <Link
//               to="/quoted_view"
//               className={isActive(`${process.env.PUBLIC_URL}/quoted_view`)}
//             >
//               <Icon name="file" />
//               <span>View</span>
//             </Link>
//           </li>
//           <li onClick={() => updateSm(false)}>
//             <Link
//               to="/rfq-approvals"
//               className={isActive(`${process.env.PUBLIC_URL}/rfq-approvals`)}
//             >
//               <Icon name="check-fill-c" />
//               <span>Approvals</span>
//             </Link>
//           </li>
//           {/* <li onClick={() => updateSm(false)}>
//                         <Link to="/rfq-supplier-responses" className={isActive(`${process.env.PUBLIC_URL}/rfq-supplier-responses`)}>
//                             <Icon name="chat" />
//                             <span>Supplier Responses</span>
//                         </Link>
//                     </li>
//                     <li onClick={() => updateSm(false)}>
//                         <Link to="/rfq-comparison" className={isActive(`${process.env.PUBLIC_URL}/rfq-comparison`)}>
//                             <Icon name="col-view" />
//                             <span>Comparison</span>
//                         </Link>
//                     </li>
//                     <li onClick={() => updateSm(false)}>
//                         <Link to="/rfq-conversion" className={isActive(`${process.env.PUBLIC_URL}/rfq-conversion`)}>
//                             <Icon name="swap" />
//                             <span>Conversion</span>
//                         </Link>
//                     </li>
//                     <li onClick={() => updateSm(false)}>
//                         <Link to="/view-rfqs" className={isActive(`${process.env.PUBLIC_URL}/view-rfqs`)}>
//                             <Icon name="eye-fill" />
//                             <span>View</span>
//                         </Link>
//                     </li> */}
//           <li onClick={() => updateSm(false)}>
//             <Link
//               to="/track-rfqs"
//               className={isActive(`${process.env.PUBLIC_URL}/track-rfqs`)}
//             >
//               <Icon name="activity-round-fill" />
//               <span>Tracking</span>
//             </Link>
//           </li>
//           {/* <li onClick={() => updateSm(false)}>
//                         <Link to="/analytics-rfq" className={isActive(`${process.env.PUBLIC_URL}/analytics-rfqs`)}>
//                             <Icon name="growth-fill" />

//                             <span>Analytics</span>
//                         </Link>
//                     </li> */}
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default QuotationsAside;
import React, { useState, useEffect, FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../components/Component"; // Assuming these are properly typed elsewhere
import { useSelector } from "react-redux";
import { RootState } from "../../store/store"; // Adjust this import based on your store configuration

interface QuotationsAsideProps {
    updateSm: (value: boolean) => void; // Function to update state
    sm: boolean; // Boolean state
}

const QuotationsAside: FC<QuotationsAsideProps> = ({ updateSm, sm }) => {
    const { data: allowedRoutes }: any = useSelector(
        (state: RootState) => state.allowedRoutes
    );
    const location = useLocation();

    useEffect(() => {
        sm
            ? document.body.classList.add("toggle-shown")
            : document.body.classList.remove("toggle-shown");
    }, [sm]);

    const isActive = (path: string): string => {
        return location.pathname === path ? "active" : "";
    };

    const LinkTo = ({ path }: { path: string }) => {
        if (!allowedRoutes.includes(path)) {
            return null;
        }
        switch (path) {
            case "view-rfqs":
                return (
                    <Link
                        to="/view-rfqs"
                        className={isActive(`${process.env.PUBLIC_URL}/view-rfqs`)}
                    >
                        <Icon name="file" />
                        <span>New Leads</span>
                    </Link>
                );
            case "quoted_view":
                return (
                    <Link
                    to="/quoted_view"
                    className={isActive(`${process.env.PUBLIC_URL}/quoted_view`)}
                    >
                    <Icon name="eye-fill" />
                        <span>View</span>
                    </Link>
                );
            case "rfq-approvals":
                return (
                    <Link
                        to="/rfq-approvals"
                        className={isActive(`${process.env.PUBLIC_URL}/rfq-approvals`)}
                    >
                        <Icon name="check-fill-c" />
                        <span>Approvals</span>
                    </Link>
                );
            case "track-rfqs":
                return (
                    <Link
                        to="/track-rfqs"
                        className={isActive(`${process.env.PUBLIC_URL}/track-rfqs`)}
                    >
                        <Icon name="activity-round-fill" />
                        <span>Tracking</span>
                    </Link>
                );
            default:
                return null;
        }
    };

    return (
        <div className="card-inner-group">
            <div className="card-inner">
                <div className="user-account-info py-0">
                    <h6 className="overline-title-alt" style={{ fontSize: 14 }}>
                        Quotations
                    </h6>
                </div>
            </div>
            <div className="card-inner p-0">
                <ul className="link-list-menu">
                    {[
                        "view-rfqs",
                        "quoted_view",
                        "rfq-approvals",
                        // "track-rfqs",
                    ].map((item) => (
                        <li key={item} onClick={() => updateSm(false)}>
                            <LinkTo path={item} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default QuotationsAside;
