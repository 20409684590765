import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Form, Row, Alert } from "reactstrap";
import { Icon, Button, RSelect } from "../../../components/Component";
import { FormProvider, useForm } from "react-hook-form";
import rolesApi from "../../../api/master/roles";
import Swal from "sweetalert2";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import FormTextArea from "../../form-components/TextAreaComponent";
import { Col } from "reactstrap";
import FormMultiSelect from "../../form-components/MultiSelectComponent";
interface SelectOptions {
  label: string;
  value: string;
}

const AddModal: FC<any> = ({
  modal,
  closeModal,
  onSubmit,
  formData,
  setFormData,
  filterStatus,
  alertInfo,
}) => {
  useEffect(() => {
    reset(formData);
  }, [formData]);
  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;
  const [roles, setRoles] = useState<SelectOptions[]>([]);
  const fetchRoles = async () => {
    try {
      const { data, status, message } = await rolesApi.fetchRolesData();
      if (status) {
        setRoles(
          data.map((obj: any) => ({
            label: obj.roleName,
            value: obj.roleID.toString(),
          }))
        );
      } else {
        Swal.fire("Error", message, "error");
      }
    } catch (err: any) {
      Swal.fire("Error", err.response?.data?.message || err.message, "error");
    }
  };
  useEffect(() => {
    fetchRoles();
  }, []);
  return (
    <Modal
      isOpen={modal}
      toggle={() => closeModal()}
      className="modal-dialog-centered"
      size="lg"
      backdrop="static"
    >
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Add General Ledger</h5>
          {alertInfo.message && (
            <div className="mb-3 mt-1">
              <Alert
                color={alertInfo.type === "error" ? "danger" : "success"}
                className="alert-icon"
              >
                <strong>{alertInfo.message}</strong>
                <Icon
                  name={
                    alertInfo.type === "error" ? "alert-circle" : "check-circle"
                  }
                />
                {alertInfo.errors.length > 0 ? (
                  <ul>
                    {alertInfo.errors.map((err: any, index: any) => (
                      <li key={index}>{err}</li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </Alert>
            </div>
          )}
          <div className="mt-4">
            <FormProvider {...methods}>
              <Form
                className="row gy-4"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <Col md="4">
                  <div className="form-group">
                    <FormInput
                      type="text"
                      label="Ledger ID"
                      name="referenceID"
                      required={true}
                      placeholder="Enter Ledger ID"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <FormInput
                      label="Ledger Name"
                      name="ledgerName"
                      required={true}
                      placeholder="Enter Ledger Name"
                    />
                  </div>
                </Col>
                <Col md="4">
                  <div className="form-group">
                    <FormMultiSelect
                      name="roles"
                      label="Select Role"
                      options={roles}
                      required={true}
                    />
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <FormTextArea
                      name="description"
                      label="Description"
                      placeholder="Write your description"
                      required={true}
                      maxLength={50}
                    />
                  </div>
                </Col>
                <Row className="mt-4">
                  <Col md="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          className="btn btn-primary btn-md"
                          type="submit"
                          color="primary"
                        >
                          {/* <Icon name="check" /> */}
                          <span>Submit</span>
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            closeModal();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default AddModal;
